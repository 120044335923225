import axios from '../plugins/axios'
import backend from "../plugins/axios";

export function getComputedLayers(specterId) {
    return axios.post(`/data/specter/computed/${specterId}`)
}

export function pixelAnalyse(specterId, payload) {
    return axios.post(`/advanced/layer/${specterId}/pixel`, payload)
}

export function computeLayer(specterId, clustering_type, payload) {
    return axios.post(`/advanced/layer/${specterId}/${clustering_type}`, payload)
}

export function buildSimilarityMap(specterId, payload) {
    return axios.post(`/advanced/layer/${specterId}/map`, payload)
}

export function cannyObjectDetection(specterId) {
    return axios.post(`/advanced/edge/${specterId}/default_canny`)
}

export function assembleSpecterFromVideos(formData,) {
    return backend.post(
        `/data/assembly`,
        formData,
        {
            headers: {'Content-Type': 'multipart/form-data'}
        })
}
