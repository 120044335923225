import backend, {remote_server} from '../plugins/axios'

export function getSpectruAndWifiSettings() {
    return backend.get(`/system/config`)
}

export function setSpectruAndWifiSettings(payload) {
    return backend.post(`/system/config`, payload)
}

export function checkRemoteAuth() {
    return backend.get('/auth/login')
}

export function remoteAuth(payload) {
    return backend.post('/auth/login', payload)
}
