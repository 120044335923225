<template>
    <v-card class="d-flex align-center flex-column" color="primary" flat>
        <v-card-actions class="w-100 justify-space-around">
            <v-btn color="error" @click="closeDialog">{{ $t('button/close') }}</v-btn>
            <v-btn :disabled="!valid" color="green" @click="addNewLayer()">
                {{ $t('newLayerDialog/addBtn') }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import LayerFactory from "@/model/Layer";

export default {
    name: "SpecificLayer",
    props: ['indexType', 'color'],
    data() {
        return {
            valid: true,
        }
    },
    methods: {
        closeDialog() {
            this.$emit('closeDialog')
        },
        addNewLayer() {
            const layer_name = this.indexType.toUpperCase()
            const layer_index = this.indexType
            const layer_color = this.color
            const layer = new LayerFactory().createLayer('index', {layer_color, layer_name, layer_index})
            this.$store.commit('addLayer', layer);
            this.closeDialog()
        }
    }
}
</script>

<style scoped>

</style>
