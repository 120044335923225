<template>
    <div class="AdvancedAnalyseView">
        <v-app-bar class="appbar" color="#4a66d2" dense>
            <v-btn class="remove-text-decoration" icon to="/specter">
                <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
            <span class="mr-auto title-text">
            {{ $t('analyse/title') }}
            </span>
            <notification-view></notification-view>
            <change-language-view></change-language-view>
            <v-app-bar-nav-icon @click.stop="actionsSidebar = !actionsSidebar"></v-app-bar-nav-icon>
        </v-app-bar>

        <indi-view :showDialog="isClusteringOverlayShowed"></indi-view>

        <v-row class="flex-row body" no-gutters>
            <v-navigation-drawer v-model="actionsSidebar" absolute color='transparent'
                                 hide-overlay
                                 right temporary>
                <v-list class="actions-list" dense nav rounded>
                    <v-list-group class="pb-1" color="white" prepend-icon="mdi-image">
                        <template v-slot:activator>
                            <v-list-item-title>{{ $t('analyse/displayingSettings') }}</v-list-item-title>
                        </template>
                        <v-list-item dense>
                            <v-list-item-title>{{ $t('analyse/stretchX') }}</v-list-item-title>
                            <v-list-item-action class="pr-4">
                                <v-checkbox v-model="stretchX" :disabled="isDrawningMode" color="white"
                                            label=""></v-checkbox>
                            </v-list-item-action>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-title>{{ $t('analyse/stretchY') }}</v-list-item-title>
                            <v-list-item-action class="pr-4">
                                <v-checkbox v-model="stretchY" :disabled="isDrawningMode" color="white"
                                            label=""></v-checkbox>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list-group>
                    <v-list-item @click="hideSettings">
                        <v-list-item-icon>
                            <v-icon v-if="isHideSettings">mdi-eye</v-icon>
                            <v-icon v-else>mdi-eye-off</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>{{ $t('analyse/hideSettings') }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="saveSettings">
                        <v-list-item-icon>
                            <v-icon>mdi-content-save</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>{{ $t('analyse/saveSettings') }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="loadSettings">
                        <v-list-item-icon class="justify-center">
                            <v-icon>mdi-upload</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>{{ $t('analyse/loadSettings') }}</v-list-item-title>
                        <input id="fileUploader" style="display: none" type="file">
                    </v-list-item>
                    <v-list-item v-if="!getServerLaunchParameter"
                                 :disabled="isDrawningMode"
                                 @click="isClusteringOverlayShowed = !isClusteringOverlayShowed">
                        <v-list-item-icon>
                            <v-icon>mdi-magnify</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>{{ $t('analyse/findIndi') }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item v-if="!getServerLaunchParameter" v-bind:class="{'btn-success': isDrawningMode}"
                                 @click="toggleChoosePixelMode">
                        <v-list-item-icon>
                            <v-icon>mdi-pencil</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>{{ $t('analyse/pixelBrightness') }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-navigation-drawer>

            <settings-view v-show="!isDrawningMode && isHideSettings"/>
            <points-config-view v-if="isDrawningMode" v-bind:isHideSettings="isHideSettings"/>
            <image-view v-bind:stretchX="stretchX" v-bind:stretchY="stretchY"/>
        </v-row>
    </div>
</template>

<script>
import IndiView from "@/components/analyse-view/IndiView";
import NotificationView from "@/components/support-view/NotificationView";
import ImageView from "@/components/analyse-view/ImageView";
import SettingsView from "@/components/setting-view/SettingsView";
import PointsConfigView from "@/components/setting-view/PointsConfig";
import ChangeLanguageView from "@/components/support-view/ChangeLanguageView";

export default {
    name: "AnalyseView",

    components: {
        IndiView,
        NotificationView,
        PointsConfigView,
        ImageView,
        SettingsView,
        ChangeLanguageView
    },
    data() {
        return {
            actionsSidebar: false,
            isHideSettings: true,
            stretchX: true,
            stretchY: true,
            isCountingOverlayShowed: false,
            isClusteringOverlayShowed: false,
        }
    },
    destroyed() {
        this.$store.commit('clearDrawPlot')
        this.$store.commit('clearClusterLayerList')
        this.$store.commit('clearObjectsList')
        if (this.isDrawningMode)
            this.toggleChoosePixelMode()
    },
    computed: {
        getLayers: function () {
            return this.$store.getters.getLayers;
        },
        isDrawningMode: function () {
            return this.$store.getters.isDrawningMode
        },
        getServerLaunchParameter() {
            return process.env.VUE_APP_PI_VERSION === 'true'
        }
    },
    methods: {
        hideSettings: function () {
            this.isHideSettings = !this.isHideSettings;
            if (this.plSize !== 0)
                this.plSize = 0;
            else
                this.plSize = 350
        },
        toggleChoosePixelMode: function () {
            this.actionsSidebar = false;
            this.stretchX = true
            this.stretchY = true;
            this.$store.commit('toggleDrawningMode')
        },
        saveSettings: function () {
            const layers = this.getLayers;
            const data = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(layers));
            const item = document.createElement('a');
            item.download = 'config_' + getCurTime();
            item.href = data;

            document.body.appendChild(item);
            item.click();
            document.body.removeChild(item);
        },
        loadSettings: function () {
            const uploader = document.getElementById('fileUploader');
            uploader.click();

            var store = this.$store

            uploader.onchange = function () {
                const file = uploader.files[0];
                const reader = new FileReader();
                reader.readAsText(file);
                reader.onload = function (event) {
                    store.commit('setLayers', JSON.parse(event.target.result));
                }
            };
        },
    }
}

function getCurTime() {
    const curDate = new Date();
    return curDate.getDate().toString() + "/"
        + (curDate.getMonth() + 1).toString() + "/"
        + curDate.getFullYear().toString() + "@"
        + curDate.getHours().toString() + "-"
        + curDate.getMinutes().toString() + "-"
        + curDate.getSeconds().toString() + ".json";
}
</script>

<style>
.AdvancedAnalyseView {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.btn-success {
    background-color: #28a745 !important;
}

.appbar {
    z-index: 3;
}

.body {
    position: relative;
    height: 100%;
    flex-wrap: nowrap;
}

.title-text {
    font-weight: bold;
    font-size: x-large;
    color: white;
    margin-left: 30px;
}


.form-inline {
    margin-right: 30px;
}

.v-input--selection-controls {
    margin-top: 0 !important;
    padding-top: 0 !important;
}

@media screen and (max-width: 500px) {
    .navbar > .title-text {
        display: none;
    }
}

.actions-list .v-list-item {
    background-color: #313f8a;
}

.remove-text-decoration {
    text-decoration: none !important;
}
</style>
