<template>
    <v-row dense no-gutters>
        <v-col cols="12" md="6">
            <v-card :height="calcHeight" :width="calcWidth" color="secondary">
                <v-card-text>{{ $t('newLayerDialog/similartyMapTitle') }}</v-card-text>
                <div id="canvas-container" class="canvas-container">
                    <canvas ref="canvas"></canvas>
                </div>
            </v-card>
        </v-col>
        <v-col cols="12" md="6">
            <v-card :height="calcHeight" :width="calcWidth" color="secondary">
                <v-card-text>{{ $t('newLayerDialog/similartyMapTitle2') }}</v-card-text>
                <img class="mapImage" v-bind:src="getMapImage">
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import {fabric} from 'fabric';
import LayerFactory, {SoMLayer} from "@/model/Layer";

export default {
    name: "ReactiveMapDrawer",
    data() {
        return {
            canvas: null,
            rectangle: new fabric.Rect({
                left: 0,
                top: 0,
                width: 0,
                height: 0,
                angle: 0,
                fill: 'rgba(255,0,0,0.5)',
                transparentCorners: true,
                stroke: 'black',
                strokeWidth: 2,
            }),
            isDrawing: false,
            specter_crop: null
        }
    },
    mounted() {
        this.canvas = new fabric.Canvas(this.$refs.canvas, {
            width: this.calcCanvasWidth,
            height: this.calcCanvasHeight,
            selectionColor: '#FF0000',
            backgroundColor: '#534fb2',
        })
        this.canvas.selection = false
        this.canvas.hoverCursor = 'pointer';

        fabric.Image.fromURL(this.getCurrentImage, (imgObg) => {
            this.canvas.setBackgroundImage(
                imgObg,
                this.canvas.renderAll.bind(this.canvas),
                {
                    scaleX: this.calcCanvasWidth / imgObg.width,
                    scaleY: this.calcCanvasHeight / imgObg.height,
                }
            )
            this.canvasSetup()
        })
    },
    destroyed() {
        this.$store.commit('removeMapImage')
    },
    computed: {
        calcCanvasWidth() {
            return document.getElementById('canvas-container').clientWidth
        },
        calcCanvasHeight() {
            return document.getElementById('canvas-container').clientHeight - 54
        },
        calcWidth() {
            return window.innerWidth * 0.7
        },
        calcHeight() {
            return window.innerHeight * 0.7
        },
        getSpecterWidth() {
            return this.$store.getters.getCurrentSpecter.width
        },
        getSpecterHeight() {
            return this.$store.getters.getCurrentSpecter.height
        },
        getCurrentImage() {
            return this.$store.getters.getImage
        },
        getMapImage() {
            return this.$store.getters.getMapImage
        }
    },
    methods: {
        addLayer() {
            const coordinates = this.getRectangleCoords()
            const layer_name = 'SOM'
            const layer = new LayerFactory().createLayer('som', {layer_name, coordinates})
            layer.specter_crop = this.specter_crop
            this.$store.commit('addLayer', layer)
        },
        buildSimilarityMap(target_object) {
            let request_data = {}
            if (target_object === undefined) {
                request_data = this.getRectangleCoords()
            } else {
                request_data = target_object
            }
            this.$store.dispatch('buildSimilarityMap', {'data': request_data})
                .then((response) => this.specter_crop = response.data.specter_crop)
        },
        getRectangleCoords() {
            let shift_x = 0;
            let shift_y = 0;
            let w_f = this.getSpecterWidth / this.canvas.width
            let h_f = this.getSpecterHeight / this.canvas.height

            if (this.rectangle.width === 0) {
                shift_x = 0;
            }
            if (this.rectangle.height === 0) {
                shift_y = 0;
            }
            return [
                [w_f * this.rectangle.left, h_f * this.rectangle.top],
                [w_f * (this.rectangle.width + this.rectangle.left) + shift_x, h_f * (this.rectangle.height + this.rectangle.top) + shift_y]
            ]
        },
        initObjectDetector() {
            const _this = this
            this.$store.dispatch('getObjectsDetectedByCanny')
                .then(response => {

                    response.data.figures.forEach(figure => {
                        let polyline = new fabric.Polyline(
                            figure.map(function (coords) {
                                return {
                                    'x': coords['x'] * _this.canvas.width / _this.getSpecterWidth,
                                    'y': coords['y'] * _this.canvas.height / _this.getSpecterHeight
                                }
                            }),
                            {
                                stroke: '#FFFFFF',
                                fill: 'rgba(255,255,255, 0)',
                                hasBorders: false,
                                hasControls: false,
                                selectable: false,
                            }
                        )
                        polyline.on('mousedown', e => {
                            _this.buildSimilarityMap(polyline.points.map(function (coords) {
                                return [
                                    coords['x'] * _this.getSpecterWidth / _this.canvas.width,
                                    coords['y'] * _this.getSpecterHeight / _this.canvas.height
                                ]
                            }))
                            e.e.stopPropagation()
                        })
                        this.canvas.add(polyline)
                    })
                })
        },
        canvasSetup() {
            const canvas = this.canvas
            const _this = this
            canvas.add(_this.rectangle)

            this.canvas.on('mouse:down', function (opt) {
                if (opt.target === null) {
                    const evt = opt.e
                    const pointerCoords = canvas.getPointer(evt)
                    _this.rectangle.set('left', pointerCoords.x)
                    _this.rectangle.set('top', pointerCoords.y)
                    _this.rectangle.set('width', 0)
                    _this.rectangle.set('height', 0)
                    _this.origX = pointerCoords.x
                    _this.origY = pointerCoords.y

                    _this.isDrawing = true
                    canvas.renderAll()
                }
            })

            this.canvas.on('mouse:move', function (opt) {
                const evt = opt.e
                if (_this.isDrawing) {
                    const pointerCoords = canvas.getPointer(evt)
                    pointerCoords.x = Math.max(0, pointerCoords.x)
                    pointerCoords.x = Math.min(_this.calcCanvasWidth, pointerCoords.x)
                    pointerCoords.y = Math.max(0, pointerCoords.y)
                    pointerCoords.y = Math.min(_this.calcCanvasHeight, pointerCoords.y)

                    if (_this.origX > pointerCoords.x)
                        _this.rectangle.set('left', Math.abs(pointerCoords.x))
                    if (_this.origY > pointerCoords.y)
                        _this.rectangle.set('top', Math.abs(pointerCoords.y))

                    let width = Math.abs(_this.origX - pointerCoords.x)
                    let height = Math.abs(_this.origY - pointerCoords.y)

                    _this.rectangle.set('width', width)
                    _this.rectangle.set('height', height)
                    canvas.renderAll()
                }
            })
            this.canvas.on('mouse:up', function (opt) {
                if (_this.isDrawing) {
                    _this.isDrawing = false
                    canvas.renderAll()

                    _this.buildSimilarityMap()
                }
            })
        }
    }
}
</script>

<style scoped>
.canvas-container {
    height: 100%;
    display: flex;
    overflow: hidden;
}

.mapImage {
    width: 100%;
    height: calc(100% - 54px);
}
</style>
