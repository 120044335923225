import backend from '../plugins/axios'

export function getHyperspecterList() {
    return backend.get('/data/specter')
}

export function deleteHyperspecter(specter_id) {
    return backend.delete(`/data/specter/${specter_id}`)
}

export function uploadNewHyperspecter(formData,) {
    return backend.post(
        '/data/specter',
        formData,
        {
            headers: {'Content-Type': 'multipart/form-data'},
            timeout: 1000 * 60 * 60
        })
}

export function updateHyperspecter(specter_id, changes) {
    return backend.put(`/data/specter/${specter_id}`, changes);
}

export function getLayers(specter_id, parameters_object) {
    return backend.post(`/base/processing/${specter_id}`, {
        body: parameters_object,
        responseType: 'blob',
    })
}

export function getSpecterPreview(specter_id) {
    return backend.get(`/data/specter/preview/${specter_id}`);
}

export function uploadVideo(formData,) {
    return backend.post(
        `/data/orthophoto`,
        formData,
        {
            headers: {'Content-Type': 'multipart/form-data'}
        })
}
