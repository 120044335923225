<template>
    <div class="text-center">
        <v-dialog
            v-model="dialog"
            width="500"
            @click:outside="closeDialog">
            <v-card class="p-2" color="primary" outlined>
                <v-select v-model="selectedOption" :items="uploaderOptions" background-color="secondary"
                          solo></v-select>
                <specter-uploader v-if="isSpecterUploader" @close="closeDialog"></specter-uploader>
                <odm-authorization v-if="isRpcamVideoUploader || isRecVideoUploader"></odm-authorization>
                <rec-video-uploader v-if="isRecVideoUploader" @close="closeDialog"></rec-video-uploader>
                <rpcam-video-uploader v-if="isRpcamVideoUploader" @close="closeDialog"></rpcam-video-uploader>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import SpecterUploader from "@/components/support-view/uploaders/SpecterUploader";
import RecVideoUploader from "@/components/support-view/uploaders/RecVideoUploader";
import OdmAuthorization from "@/components/support-view/uploaders/OdmAuthorization";
import RpcamVideoUploader from "@/components/support-view/uploaders/RpcamVideoUploader";

const SPECTER_OPTION = 'Specter'
const RPCAM_VIDEO_OPTION = 'Camera Video'
const REC_VIDEO_OPTION = 'Hyperspectral Video'

export default {
    name: "UploaderView",
    components: {RpcamVideoUploader, OdmAuthorization, RecVideoUploader, SpecterUploader},
    props: ['dialog'],
    data() {
        return {
            uploaderOptions: [SPECTER_OPTION, RPCAM_VIDEO_OPTION, REC_VIDEO_OPTION],
            selectedOption: SPECTER_OPTION
        }
    },
    computed: {
        isSpecterUploader() {
            return this.selectedOption === SPECTER_OPTION
        },
        isRpcamVideoUploader() {
            return this.selectedOption === RPCAM_VIDEO_OPTION
        },
        isRecVideoUploader() {
            return this.selectedOption === REC_VIDEO_OPTION
        },

    },
    methods: {
        closeDialog() {
            this.selectedOption = SPECTER_OPTION
            this.$emit('close')
        }
    }
}
</script>

<style scoped>

</style>
