import Vue from 'vue';
import VueKonva from 'vue-konva'
import VueHtml2Canvas from 'vue-html2canvas';
import App from './App.vue';
import {vuetify} from './plugins/vuetify';
import {router} from './plugins/route';
import {i18n} from "./plugins/translations";
import {store} from "./plugins/vuex";
import "./plugins/toast";
import "./plugins/socketio"

// Used for prevention VueHtml2Canvas import error
import "regenerator-runtime/runtime"
import '@mdi/font/css/materialdesignicons.min.css'


Object.defineProperty(Vue.prototype, '$locale', {
    get: function () {
        return i18n.locale
    },
    set: function (locale) {
        i18n.locale = locale
    }
});


Vue.use(VueHtml2Canvas)
Vue.use(VueKonva)

new Vue({
    vuetify,
    router,
    i18n,
    store,
    render: h => h(App)
}).$mount('#app');
