<template>
    <div class="authorization-view">
        <nav class="navbar navbar-expand-lg navbar-light">
        <span class="mr-auto title-text">
            Authorization
        </span>
        </nav>
        <div class="body" @keyup.enter="login">
            <v-form ref="form" v-model="valid" class="login-form" lazy-validation>
                <v-text-field id="login-field"
                              v-model="username"
                              autofocus
                              color="#4a66d2"
                              dark
                              filled
                              label="Username: "
                              required
                ></v-text-field>
                <v-text-field id="password-field"
                              v-model="password"
                              :append-icon="isShowed ? 'mdi-eye' : 'mdi-eye-off'"
                              :type="isShowed ? 'text' : 'password'"
                              color="#4a66d2"
                              dark
                              filled
                              label="Password: "
                              required
                              @click:append="isShowed = !isShowed">
                </v-text-field>
                <v-btn id="login-button" color="success" @click="login">Sign in</v-btn>
                <!--                <hr>-->
                <!--                <v-btn @click="withoutLogin" color="secondary">Continue without sign in</v-btn>-->
            </v-form>
        </div>
    </div>
</template>

<script>
export default {
    name: "AuthorizationView",
    data() {
        return {
            valid: true,
            isShowed: false,
            username: '',
            password: '',
        }
    },
    methods: {
        login: function () {
            const user = {username: this.username, password: this.password};
            this.$store.dispatch('authRequest', user)
                .then(() => {
                    this.$router.push({name: 'HyperspecterView'})
                    this.$toast.success(this.$t('notification/successLogin'))
                })
        }
    }
}
</script>

<style scoped>
.authorization-view {
    display: flex;
    height: 100%;
}

.navbar {
    display: flex;

    background: #4a66d2;
    position: fixed;
    z-index: 10;
    width: 100%;

    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
}

.title-text {
    font-weight: bold;
    font-size: x-large;
    color: white;
    margin-left: 30px;
}

.body {
    padding-top: 54px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100%;
    width: 100%;
}

.login-form {
    display: flex;
    flex-direction: column;
}
</style>
