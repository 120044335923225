<template>
    <v-dialog
        v-model="dialog"
        width="500px"
        @click:outside="closeDialog">
        <v-card class="p-2" color="primary" outlined>
            <v-card color="secondary">
                <v-card-title>WiFi Settings</v-card-title>
                <v-card-text>
                    <v-list color="secondary">
                        <v-form v-model="validWifi">
                            <v-text-field v-model="wifiSettings.ssid" label="SSID"></v-text-field>
                            <v-text-field v-model="wifiSettings.password" label="Password"
                                          type="password"></v-text-field>
                        </v-form>
                    </v-list>
                </v-card-text>
            </v-card>
            <v-card color="secondary">
                <v-card-title>Spectru Settings</v-card-title>
                <v-card-text>
                    <v-list color="secondary">
                        <v-form v-model="validSpectru">
                            <v-text-field v-model="spectruSettings.spectral_bands_number"
                                          label="Spectral bands number"></v-text-field>
                            <v-switch v-model="spectruSettings.postprocessing_scaling_gray_levels"
                                      label="Postprocessing scaling gray levels"></v-switch>
                            <v-switch v-model="spectruSettings.postprocessing_fix_vignetting"
                                      label="Postprocessing fix vignetting"></v-switch>
                        </v-form>
                    </v-list>
                </v-card-text>
            </v-card>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="success" @click='updateSettings'>Update Settings</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "system-settings",
    props: {
        dialog: Boolean
    },
    data() {
        return {
            wifiSettings: {
                ssid: '',
                password: ''
            },
            spectruSettings: {
                spectral_bands_number: '',
                postprocessing_scaling_gray_levels: false,
                postprocessing_fix_vignetting: false
            }
        }
    },
    computed: {
        getServerLaunchParameter() {
            return Number(process.env.VUE_APP_PI_VERSION)
        }
    },
    mounted() {
        this.setCurrentSettings()
    },
    methods: {
        closeDialog() {
            this.$emit('close')
        },
        setCurrentSettings() {
            this.$store.dispatch('getSpectruAndWifiSettings')
                .then(res => {
                    res = res.data
                    this.wifiSettings.ssid = res.ssid
                    this.spectruSettings.spectral_bands_number = res.spectral_bands_number
                    this.spectruSettings.postprocessing_scaling_gray_levels = res.postprocessing_scaling_gray_levels
                    this.spectruSettings.postprocessing_fix_vignetting = res.postprocessing_fix_vignetting
                })
        },
        updateSettings() {
            console.log({...this.wifiSettings, ...this.spectruSettings});
            let payload = {...this.wifiSettings, ...this.spectruSettings}
            this.$store
                .dispatch('setSpectruAndWifiSettings', payload)
                .then(() => this.closeDialog())
        }
    }
}
</script>

<style scoped>

</style>
