<template>
    <v-card id="object-selection-view" :height="calcHeight" :width="calcWidth" class="object-selection-view"
            color="primary">
        <v-card-title>{{ $t('indiDialog/newTitle') }}</v-card-title>
        <div id="canvas-container" class="canvas-container">
            <canvas ref="canvas"></canvas>
        </div>
        <v-card-actions>
            <v-btn color="red" @click="onBack">{{ $t('button/back') }}</v-btn>
            <v-spacer></v-spacer>
            <v-btn @click="startDrawing(0)">{{ $t('indiDialog/btnClass1') }}</v-btn>
            <v-btn @click="startDrawing(1)">{{ $t('indiDialog/btnClass2') }}</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="success" @click="onNext">{{ $t('analyse/findIndi') }}</v-btn>
        </v-card-actions>
    </v-card>
</template>


<script>
import {fabric} from 'fabric';

export default {
    name: "IndiObjectSelection",
    data() {
        return {
            canvas: null,
            isDrawing: false,
            lines: [],
            roofPoints: [],
            linesCounter: 0,
            objectClass: 0,

            objects: [[], []],
        }
    },
    mounted() {
        this.canvas = new fabric.Canvas(this.$refs.canvas, {
            width: this.calcCanvasWidth,
            height: this.calcCanvasHeight,
            selectionColor: '#FF0000',
            backgroundColor: '#534fb2',
        })
        this.canvas.selection = false

        fabric.Image.fromURL(this.getCurrentImage, (imgObg) => {
            this.canvas.setBackgroundImage(
                imgObg,
                this.canvas.renderAll.bind(this.canvas),
                {
                    scaleX: this.calcCanvasWidth / imgObg.width,
                    scaleY: this.calcCanvasHeight / imgObg.height,
                }
            )
            this.canvasSetup()
        })

        document.addEventListener('keyup', this.cancelDrawing)
    },
    destroyed() {
        document.removeEventListener('keyup', this.cancelDrawing)
    },
    computed: {
        calcCanvasWidth() {
            return document.getElementById('canvas-container').clientWidth
        },
        calcCanvasHeight() {
            return document.getElementById('canvas-container').clientHeight
        },
        calcWidth() {
            return window.innerWidth * 0.7
        },
        calcHeight() {
            return window.innerHeight * 0.7
        },
        getSpecterWidth() {
            return this.$store.getters.getCurrentSpecter.width
        },
        getSpecterHeight() {
            return this.$store.getters.getCurrentSpecter.height
        },
        getCurrentImage() {
            return this.$store.getters.getImage
        },
        getObjects: {
            cache: false,
            get() {
                return this.objects
            }
        }
    },
    methods: {
        cancelDrawing() {
            this.lines.forEach((item) => {
                this.canvas.remove(item)
            })
            this.lines = []
            this.linesCounter = 0
            this.isDrawing = false
            this.roofPoints = []
        },
        onBack() {
            this.$emit('back')
        },
        startDrawing(classNum) {
            this.isDrawing = true
            this.objectClass = classNum
        },
        onNext() {
            console.log(this.objects)
            this.objects.forEach((_class, classIndex) => {
                _class.forEach((figure, figureIndex) => {
                    figure.forEach((point, pointIndex) => {
                        // classes, figures, points, coordinates
                        this.objects[classIndex][figureIndex][pointIndex] = []
                        this.objects[classIndex][figureIndex][pointIndex][0] =
                            this.getSpecterWidth / this.canvas.width * point.x
                        this.objects[classIndex][figureIndex][pointIndex][1] =
                            this.getSpecterHeight / this.canvas.height * point.y
                    })
                })
            })
            this.$emit('calculateINDI', this.objects[0], this.objects[1])
        },
        canvasSetup() {
            console.log('[INFO] Setting up canvas event')
            // get it from http://fabricjs.com/fabric-intro-part-5#pan_zoom
            const zoomCoef = typeof InstallTrigger !== 'undefined' ? 0.989 : 0.9993
            const canvas = this.canvas
            const _this = this

            this.canvas.on('mouse:wheel', function (opt) {
                const delta = opt.e.deltaY
                let zoom = canvas.getZoom()
                zoom *= zoomCoef ** delta
                if (zoom > 20) zoom = 20
                if (zoom < 0.01) zoom = 0.01
                canvas.zoomToPoint({x: opt.e.offsetX, y: opt.e.offsetY}, zoom)
                opt.e.preventDefault()
                opt.e.stopPropagation()
            })

            fabric.util.addListener(canvas.upperCanvasEl, 'dblclick', function (e) {
                if (_this.isDrawing && _this.linesCounter > 1) {
                    _this.lines.forEach((item) => {
                        canvas.remove(item)
                    })
                    _this.lines = []
                    _this.linesCounter = 0
                    _this.isDrawing = false

                    _this.roofPoints.pop()  // one element is unnecessary due to result of mouse:down event
                    _this.roofPoints.push(_this.roofPoints[0])  // for closing a figure
                    _this.objects[_this.objectClass].push(_this.roofPoints)
                    const roof = new fabric.Polyline(_this.roofPoints, {
                        fill: _this.objectClass === 1 ? 'rgba(0, 255, 0, 0.5)' : 'rgba(255, 0, 0, 0.5)',
                        stroke: '#58c',
                        hasBorders: false,
                        hasControls: false,
                        selectable: true,
                    })
                    _this.roofPoints = []

                    canvas.add(roof)
                    canvas.renderAll()
                }
            })

            this.canvas.on('mouse:down', function (opt) {
                const evt = opt.e
                if (_this.isDrawing) {
                    const pointerCoords = canvas.getPointer(evt)
                    _this.roofPoints.push({x: pointerCoords.x, y: pointerCoords.y})
                    const points = [pointerCoords.x, pointerCoords.y, pointerCoords.x, pointerCoords.y]
                    _this.lines.push(new fabric.Line(points, {
                        strokeWidth: 3,
                        selectable: false,
                        stroke: _this.objectClass === 1 ? 'green' : 'red'
                    }))
                    canvas.add(_this.lines[_this.linesCounter])
                    _this.linesCounter++
                }
                if (evt.altKey === true) {
                    this.isDragging = true
                    this.lastPosX = evt.clientX
                    this.lastPosY = evt.clientY
                }
            })

            this.canvas.on('mouse:move', function (opt) {
                const evt = opt.e
                if (_this.isDrawing && _this.linesCounter !== 0) {
                    const pointerCoords = canvas.getPointer(evt)
                    _this.lines[_this.linesCounter - 1].set({
                        x2: pointerCoords.x,
                        y2: pointerCoords.y
                    })
                    canvas.renderAll()
                } else if (this.isDragging) {
                    const e = opt.e
                    const vpt = this.viewportTransform
                    vpt[4] += e.clientX - this.lastPosX
                    vpt[5] += e.clientY - this.lastPosY
                    this.requestRenderAll()
                    this.lastPosX = e.clientX
                    this.lastPosY = e.clientY
                }
            })
            this.canvas.on('mouse:up', function (opt) {
                // on mouse up we want to recalculate new interaction
                // for all objects, so we call setViewportTransform
                this.setViewportTransform(this.viewportTransform)
                this.isDragging = false
            })

            this.canvas.on({
                'object:moving'(e) {
                    e.target.opacity = 0.5
                },
                'object:modified'(e) {
                    e.target.opacity = 1
                },
            })
        }
    }
}
</script>

<style scoped>
.canvas-container {
    height: 100%;
    flex-grow: 1;
    display: flex;
    overflow: hidden;
}

.object-selection-view {
    padding: 0 5px 5px 5px;
    display: flex;
    flex-direction: column;
}

polygon.hoverable {
    fill: transparent;
    stroke: gray;
    stroke-width: 1px;
    cursor: pointer;
}

polygon.hoverable:hover {
    stroke: whitesmoke;
}
</style>
