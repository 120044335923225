import backend from '../axios'
import {
    assembleSpecter, fetchVideoConfig,
    getCameraConfig,
    getMemoryStatus,
    hypercamShowPicture, removeVideo,
    rotateTripod,
    saveCameraConfig,
    startRecording, uploadOnServer
} from "@/api/CameraAPI";

export const vuexFlightModule = {
    state: {
        logs: '',

        videoData: [],
        shootingStatus: false,

        cameraConfig: {
            tcam_Exposure: 13000,
            tcam_Gain: 16,
            tcam_first_line: 0,
            tcam_last_line: 330,
            tcam_tripod_capture_lines: 1000,
            lines: 100
        }
    },
    getters: {
        getVideoData: state => state.videoData,
        getShootingStatus: state => state.shootingStatus,
        getLogs: state => state.logs,
        getCameraConfig: state => state.cameraConfig,
    },
    mutations: {
        setVideoData(state, data) {
            state.videoData = data
        },
        appendLineToLogs(state, line) {
            state.logs = `${line} \n` + state.logs
        },
        clearLogs(state) {
            state.logs = ''
        },
        setCameraConfig(state, cameraConfig) {
            state.cameraConfig = cameraConfig
        }
    },
    actions: {
        SOCKET_status({commit}, server) {
            commit('appendLineToLogs', server.data)
        },
        updateServerTime({commit}, payload) {
            return new Promise((resolve, reject) => {
                backend({url: 'utils/time', method: 'POST', data: payload})
                    .then(resp => {
                        resolve(resp);
                    })
                    .catch(err => {
                        reject(err);
                    })

            })
        },
        saveCameraConfig({commit}, config) {
            commit('setCameraConfig', config)

            return new Promise(((resolve, reject) => {
                saveCameraConfig(config)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            }))
        },
        loadCameraConfig({commit}) {
            return new Promise(((resolve, reject) => {
                getCameraConfig()
                    .then(res => {
                        commit('setCameraConfig', res.data)
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            }))
        },
        initImageShowing({state, commit, dispatch}) {
            const formData = new FormData()
            formData.append('tcam_tripod_capture_lines', state.cameraConfig.tcam_tripod_capture_lines)
            formData.append('tcam_Exposure', state.cameraConfig.tcam_Exposure)
            formData.append('tcam_Gain', state.cameraConfig.tcam_Gain)
            formData.append('tcam_first_line', state.cameraConfig.tcam_first_line)
            formData.append('tcam_last_line', state.cameraConfig.tcam_last_line)

            return new Promise(((resolve, reject) => {
                hypercamShowPicture(formData)
                    .catch(err => {
                        reject(err)
                    })
            }))
        },
        rotateTripod({state, commit, dispatch}, payload) {
            const formData = new FormData()
            formData.append('lines', state.cameraConfig.lines)
            formData.append('direction', payload.direction)

            return new Promise(((resolve, reject) => {
                rotateTripod(formData)
                    .catch(err => {
                        reject(err)
                    })
            }))
        },
        startRecording({state, commit, dispatch}, payload) {
            const formData = new FormData()
            formData.append('tcam_tripod_capture_lines', state.cameraConfig.tcam_tripod_capture_lines)
            formData.append('tcam_Exposure', state.cameraConfig.tcam_Exposure)
            formData.append('tcam_Gain', state.cameraConfig.tcam_Gain)
            formData.append('tcam_first_line', state.cameraConfig.tcam_first_line)
            formData.append('tcam_last_line', state.cameraConfig.tcam_last_line)

            return new Promise(((resolve, reject) => {
                startRecording(formData)
                    .then(resp => {
                        dispatch('fetchVideoConfig')
                        if (resp.data.success) {
                            resolve(resp)
                        } else {
                            reject(resp)
                        }
                    })
                    .catch(err => {
                        dispatch('fetchVideoConfig')
                    })
            }))
        },
        assembleSpecter({commit, dispatch}, videoId) {
            return new Promise(((resolve, reject) => {
                assembleSpecter(videoId)
                    .then(resp => {
                        dispatch('fetchVideoConfig')
                        resolve(resp)
                    })
                    .catch(err => {
                        reject(err)
                    })
            }))
        },
        uploadOnServer({commit, dispatch}, payload) {
            return new Promise(((resolve, reject) => {
                uploadOnServer(payload)
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        reject(err)
                    })
            }))
        },
        removeVideo({commit, dispatch}, payload) {
            return new Promise(((resolve, reject) => {
                removeVideo(payload)
                    .then(resp => {
                        dispatch('fetchVideoConfig')
                        resolve(resp)
                    })
                    .catch(err => {
                        reject(err)
                    })
            }))
        },
        fetchVideoConfig({commit, dispatch}) {
            return new Promise(((resolve, reject) => {
                fetchVideoConfig()
                    .then(resp => {
                        commit('setVideoData', resp.data)
                        resolve(resp)
                    })
                    .catch(err => {
                        reject(err)
                    })
            }))
        }
    }
};
