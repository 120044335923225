import Vuex from "vuex"
import Vue from 'vue'
import {vuexAuthModule} from "./vuex/auth";
import {vuexDataProviderModule} from "./vuex/dataProvider";
import {vuexUtilsModule} from "./vuex/utils";
import {vuexDataProcessingModule} from "./vuex/processing";
import {vuexFlightModule} from "@/plugins/vuex/flight";
import {vuexAdminModule} from "@/plugins/vuex/admin";

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        auth: vuexAuthModule,
        dataProvider: vuexDataProviderModule,
        utils: vuexUtilsModule,
        dataProcessing: vuexDataProcessingModule,
        admin: vuexAdminModule
    }
});

if (process.env.VUE_APP_PI_VERSION) {
    store.registerModule('flights', vuexFlightModule)
}

export {store}
