import Vue from 'vue'
import Router from 'vue-router'
import ServiceUnavailableView from '@/pages/ServiceUnavailableView'
import AuthorizationView from '@/pages/AuthorizationView'
import AnalyseView from "@/pages/AnalyseView";
import HyperspecterView from "@/pages/HyperspecterView";
import AdminPage from "@/pages/AdminPage";

const lazyFlightsView = () => import('@/pages/FlightsView')
const lazyAdminPage = () => import('@/pages/AdminPage')

Vue.use(Router);

const router = new Router({
    mode: 'history',
    routes: [
        {
            path: '/analyse',
            name: 'AnalyseView',
            component: AnalyseView,
        },
        {
            path: '/specter',
            name: 'HyperspecterView',
            component: HyperspecterView,
        },
        {
            path: '/unavailable',
            name: 'ServiceUnavailable',
            component: ServiceUnavailableView,
            beforeEnter: (to, from, next) => {
                if (from.name === 'HyperspecterView') {
                    next();
                } else {
                    next('/')
                }
            }
        }
    ]
});


if (Number(process.env.VUE_APP_PI_VERSION)) {
    router.addRoutes([
        {
            path: '/flight',
            name: 'FlightsView',
            component: lazyFlightsView,
        },
        {
            path: '*',
            redirect: {name: 'HyperspecterView'}
        }])
} else {
    router.addRoutes([
        {
            path: '/auth',
            name: 'AuthorizationView',
            component: AuthorizationView,
        },
        {
            path: '/admin',
            name: 'AdminPage',
            component: lazyAdminPage
        },
        {
            path: '*',
            redirect: {name: 'HyperspecterView'},
        }
    ])
}
export {router}
