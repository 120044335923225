<template>
    <v-card color="secondary">
        <v-card-title class="headline" primary-title>
            {{ $t('specterUploader/RpcamVideoTitle') }}
        </v-card-title>

        <v-card-text>
            <v-file-input id="video-input" :hint="loaderHint"
                          :label="$t('specterUploader/RpcamFileFormats')"
                          accept=".avi,.mp4,.zip,.rar"
                          clearable
                          color="white"
                          counter
                          multiple
                          show-size
                          small-chips
                          @change="checkFileInputFormat"
                          @click:clear="clearFileInput" @click.stop></v-file-input>
            <v-file-input id="gps-input" :disabled="isArchive" :label="$t('specterUploader/GpsFile')"
                          accept=".csv"
                          color="white" truncate-length="50" @click.stop></v-file-input>
            <v-file-input id="json-input" :disabled="isArchive" :label="$t('specterUploader/configFile')"
                          accept=".json"
                          color="white" truncate-length="50" @click.stop></v-file-input>
        </v-card-text>

        <v-card-actions>
            <span class="d-flex align-content-center pb-5 pl-5">{{ $t('specterUploader/visibility') }}:</span>
            <v-switch v-model="isVisible" class="pl-3" color="info"></v-switch>
        </v-card-actions>

        <v-card-actions>
            <v-btn color="red" @click="closeDialog">
                {{ $t('button/close') }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="success" @click="sendFiles">
                {{ $t('button/upload') }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
    name: "RecVideoUploader",
    data() {
        return {
            isVisible: false,
            isArchive: false
        }
    },
    computed: {
        loaderHint() {
            if (this.isArchive)
                return this.$t("hint/archiveVideoInput")
            else
                return ""
        }
    },
    methods: {
        closeDialog() {
            this.$emit('close')
        },
        clearFileInput() {
            const videoInput = document.getElementById('video-input')
            if (!videoInput || !videoInput.files)
                videoInput.files = null
        },
        checkFileInputFormat() {
            const videoInput = document.getElementById('video-input')
            if (!videoInput || !videoInput.files || videoInput.files.length === 0) {
                this.isArchive = false
                return
            }
            const videoInputFilename = videoInput.files[0].name
            const fileFormat = videoInputFilename.slice(videoInputFilename.length - 4, videoInputFilename.length)
            this.isArchive = fileFormat === '.zip'
        },
        sendFiles() {
            const videoInput = document.getElementById('video-input')
            const jsonInput = document.getElementById('json-input')
            const gpsInput = document.getElementById('gps-input')

            if (!videoInput.files || videoInput.files.length === 0) {
                this.$toast.warning(this.$t('notification/ErrorNotEmptyField'))
                return
            }

            let formData = new FormData();
            formData.append('author', localStorage.getItem('username'))
            formData.append('visible', this.isVisible);
            formData.append('isArchive', this.isArchive)
            const files = [].slice.call(videoInput.files)
            files.sort()
            videoInput.files.forEach((file, index) => {
                formData.append(`rec_${index}`, file)
            })

            if (!this.isArchive) {
                if (!gpsInput.files || gpsInput.files.length === 0 || !jsonInput.files || jsonInput.files.length === 0) {
                    this.$toast.warning(this.$t('notification/ErrorNotEmptyField'))
                    return
                }
                gpsInput.files.forEach((file, index) => {
                    formData.append(`gps_${index}`, file);
                })
                jsonInput.files.forEach((file, index) => {
                    formData.append(`json_${index}`, file)
                })
            }

            this.$store.dispatch('assembleSpecterFromVideos', formData)
                .then(() => this.$toast.info(this.$t('notification/filesSended')))
                .catch(() => this.$toast.error(this.$t('notification/filesSendError')))
            this.$toast.info(this.$t('notification/sendToAssemble'))
            this.closeDialog()
        }
    }
}
</script>

<style scoped>

</style>
