<template>
    <v-card color="primary" flat>
        <v-flex class="d-flex justify-content-center flex-wrap flex-row flex-grow-0">
            <v-card v-for="card in cards" :key="card.layer_name" class="w-50"
                    max-width="260px"
                    min-width="260px"
                    color="primary"
                    flat>
                <card-view
                    v-bind:layer="card"
                ></card-view>
            </v-card>
        </v-flex>
        <v-card-actions class="justify-space-around">
            <v-btn color="red" @click="closeDialog">{{ $t('button/close') }}</v-btn>
            <!--            <v-btn color="warning" @click="addCard">Выбрать еще</v-btn>-->
            <v-btn color="green" @click="addLayer">{{ $t('newLayerDialog/addBtn') }}</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import CardView from "@/components/setting-view/CardView";

export default {
    name: "PlainLayer",
    components: {CardView},
    data() {
        return {
            cards: [],
        }
    },
    mounted() {
        this.addCard()
    },
    computed: {
        getSpecter() {
            return this.$store.getters.getCurrentSpecter
        },
    },
    methods: {
        closeDialog() {
            this.$emit('closeDialog')
        },
        addCard() {
            const layer = {
                apply_threshold: false,
                isMinimized: true,
                isShowed: true,
                layer_clahe: false,
                layer_color: '#0000FFFF',
                layer_id: 10,
                layer_name: this.computedLayerName(10),
                layer_slider: [100, 255],
                type: 'plain'
            }
            this.cards.push(layer)
        },
        computedLayerName(layer_id) {
            const layerNumber = Number(layer_id);
            let cur_layer = this.getSpecter['layers'][layerNumber];
            if (cur_layer !== undefined)
                return cur_layer['name'];
            else
                return 'Unknown layer'
        },
        addLayer() {
            this.cards.forEach((item) => {
                this.$store.commit('addLayer', item);
            })
            this.closeDialog()
        }
    }
}
</script>

<style scoped>

</style>
