<template>
    <v-menu
        v-model="menu"
        :close-on-content-click="false"
        :nudge-width="250"
        offset-y>
        <template v-slot:activator="{ on, attrs }">
            <v-badge :content="getAllTaskList.length" :value="getAllTaskList.length" color="black" overlap>
                <v-btn
                    v-bind="attrs"
                    v-on="on"
                    :disabled="getAllTaskList.length === 0"
                    color="white"
                    dark
                    text>
                    <v-icon>mdi-bell</v-icon>
                </v-btn>
            </v-badge>
        </template>

        <v-card class="p-2" color="secondary" rounded>
            <v-card-title>Notifications</v-card-title>
            <v-list color="transparent" rounded>
                <v-list-item v-for="task in getAllTaskList" v-bind:key="task.task_id"
                             :style="getColor(task.task_status)"
                             @click="onTaskClick(task)"
                             @click.right.prevent="onRightTaskClick(task)">
                    <v-list-item-avatar>
                        <v-icon>{{ getStatusIcon(task.task_status) }}</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title>
                            <span>{{ task.task_type }}</span>
                            <span v-if="task.task_name !== ''">: {{ task.task_name }}</span>
                            <span v-else>: #{{ task.specter_id }}</span>
                        </v-list-item-title>
                        <v-list-item-subtitle>{{ getSubtitle(task) }}</v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" @click="readAll">Read all</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-menu>
</template>

<script>
import addLayer from "@/service/LayerService";
import LayerFactory from "@/model/Layer";

export default {
    name: "NotificationView",
    data() {
        return {
            menu: false
        }
    },
    computed: {
        getUnreadMessagesCount() {
            return this.$store.getters.getUnreadMessagesCount
        },
        getAllTaskList() {
            return this.$store.getters.getAllTasksList
        },
        getFinishedTaskList() {
            return this.$store.getters.getFinishedTaskList
        },
        getCurrentSpecter() {
            return this.$store.getters.getCurrentSpecter
        },
        getSubtitle() {
            return task => {
                if (task.end_time && task.end_time !== '') {
                    return `Finished at: ${task.end_time}`
                } else {
                    return `Started at: ${task.start_time}`
                }
            }
        }
    },
    mounted() {
        this.startSocketEventLoop()
        setInterval(this.startSocketEventLoop, 5000)
        // this.$socket.emit('task_connect' )
    },
    destroyed() {
        clearInterval(this.startSocketEventLoop)
    },
    methods: {
        readAll() {
            this.$socket.emit('task_clear_messages', this.getFinishedTaskList)
        },
        startSocketEventLoop() {
            this.$socket.emit('task_status')
        },
        getStatusIcon(status) {
            switch (status) {
                case 'PENDING':
                    return 'mdi-clock-outline'
                case 'STARTING':
                    return 'mdi-autorenew'
                case 'RETRY':
                    return 'mdi-update'
                case 'FAILURE':
                    return 'mdi-alert-circle-outline'
                case 'SUCCESS':
                    return 'mdi-check'
            }
            return 'mdi-alert-circle-outline'
        },
        getColor(status) {
            switch (status) {
                case 'PENDING':
                    return {'background-color': '#1976d2'}
                case 'STARTING':
                    return {'background-color': '#1976d2'}
                case 'RETRY':
                    return {'background-color': '#ffc107'}
                case 'FAILURE':
                    return {'background-color': '#ff5252'}
                case 'SUCCESS':
                    return {'background-color': '#4caf50'}
            }
            return {'background-color': '#ff5252'}
        },
        onRightTaskClick(task) {
            if (task.task_status !== 'SUCCESS' && task.task_status !== 'FAILURE')
                return
            this.$socket.emit('task_clear', task.task_id)
        },
        onTaskClick(task) {
            if (task.task_status !== 'SUCCESS')
                return

            if (this.getCurrentSpecter && this.getCurrentSpecter.id == task.specter_id) {
                addLayer(task.task_result, task.task_type)
            } else {
                if (task.task_type == 'ODM' || task.task_type == 'Builder') {
                    this.$store.dispatch('fetchNewData').then(() => {
                        this.openSpecter(task)
                    })
                }
                this.openSpecter(task)
            }
        },
        openSpecter(task) {
            this.$store.commit('setChoosenSpecterById', Number(task.specter_id));

            const specter = this.$store.getters.getCurrentSpecter;

            this.$store.commit('clearLayersList');
            this.$store.commit('clearImage');

            const r = new LayerFactory().createLayer('plain', {}, specter['r'], specter['layers'][specter['r'] - 1].name,
                '#FF0000FF', false, [160, 190], false);
            const g = new LayerFactory().createLayer('plain', {}, specter['g'], specter['layers'][specter['g'] - 1].name,
                '#00FF00FF', false, [160, 190], false);
            const b = new LayerFactory().createLayer('plain', {}, specter['b'], specter['layers'][specter['b'] - 1].name,
                '#0000FFFF', false, [160, 190], false);

            this.$store.commit('addLayer', r);
            this.$store.commit('addLayer', g);
            this.$store.commit('addLayer', b);

            // It means we have result of layer processing like PCA, etc.
            if (task.task_type !== 'ODM' && task.task_type !== 'Builder') {
                addLayer(task.task_result, task.task_type)
            }

            this.$router.push({name: 'AdvancedAnalyseView'})
        }
    }
}
</script>

<style scoped>

</style>
