<template>
    <v-card color="secondary">
        <v-card-title class="headline" primary-title>
            {{ $t('specterUploader/title') }}
        </v-card-title>

        <v-card-text>
            <v-file-input id="npy-input" :label="$t('specterUploader/specterFile')"
                          accept=".npy,.tiff,.zip,.jpg,.tif,.png"
                          color="white" show-size @click.stop></v-file-input>
            <v-file-input id="json-input" :label="$t('specterUploader/configFile')"
                          accept=".json" color="white" @click.stop></v-file-input>
        </v-card-text>

        <v-card-actions>
            <span class="d-flex align-content-center pb-5 pl-5">{{ $t('specterUploader/visibility') }}:</span>
            <v-switch v-model="isVisible" class="pl-3" color="info"></v-switch>
        </v-card-actions>

        <v-card-actions class="d-flex flex-column align-items-baseline">
                    <span class="d-flex align-content-center pb-5 pl-5">{{
                            $t('specterUploader/normalizationMode')
                        }}:</span>
            <v-radio-group v-model="normalizationMode" class="pl-5">
                <v-radio :label="$t('specterUploader/globalNorm')" :value="'global'"></v-radio>
                <v-radio :label="$t('specterUploader/layerNorm')" :value="'layer'"></v-radio>
                <v-radio :label="$t('specterUploader/layerClip')" :value="'clip'"></v-radio>
            </v-radio-group>
        </v-card-actions>

        <v-card-actions>
            <v-btn color="red" @click="closeDialog">
                {{ $t('button/close') }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="success" @click="sendFiles">
                {{ $t('button/upload') }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
    name: "SpecterUploader",
    data() {
        return {
            isLoading: false,
            isVisible: false,
            normalizationMode: 'global'
        }
    },
    methods: {
        closeDialog() {
            this.$emit('close')
        },
        sendFiles: function () {
            const npyInput = document.getElementById('npy-input');
            const jsonInput = document.getElementById('json-input');
            if (!npyInput.files || npyInput.files.length === 0) {
                this.$toast.warning(this.$t('notification/ErrorNotEmptyField'));
                return;
            }

            let formData = new FormData();
            formData.append('author', localStorage.getItem('username'));
            formData.append('visible', this.isVisible);
            formData.append('normalization', this.normalizationMode);
            formData.append('specter', npyInput.files[0]);
            if (jsonInput.files || jsonInput.files.length !== 0)
                formData.append('config', jsonInput.files[0]);

            this.isLoading = true;
            this.$store.dispatch('uploadHyperspecter', formData)
            this.closeDialog()
        }
    }
}
</script>

<style scoped>

</style>
