<template>
    <v-card class="card" outlined @mouseleave="hover = false" @mouseover="hover = true">
        <v-img :src="specter.preview" class="image-display" height="300"
               width="300">
            <template v-slot:placeholder>
                <v-row
                    align="center"
                    class="fill-height ma-0"
                    justify="center"
                >
                    <v-progress-circular color="grey lighten-5" indeterminate></v-progress-circular>
                </v-row>
            </template>
            <span v-if="hover" class="id-span">#{{ specter.id }}</span>

            <v-card-title class="d-flex flex-nowrap justify-space-between">
                <div :class="{'animated-text': hover}" class="card-title">
                    <span>{{ specter.name }}</span>
                </div>
                <v-spacer></v-spacer>
                <div class="cursor-pointer" v-on:click="showDeleteDialog">
                    <v-icon>mdi-delete</v-icon>
                </div>
            </v-card-title>
            <v-card-text class="image-trigger cursor-pointer" @click="choose"></v-card-text>
            <v-card-title class="d-flex justify-space-between cursor-pointer">
                <div @click="showInformationDialog">
                    <v-icon>mdi-magnify</v-icon>
                </div>
                <div v-on:click="downloadSpecter(specter)">
                    <v-icon>mdi-download</v-icon>
                </div>
                <div v-if="getServerLaunchParameter && isRemoteAuthentificated" @click="uploadSpecter(specter)">
                    <v-icon v-if="!serverLoading">mdi-upload</v-icon>
                    <v-progress-circular v-else
                                         color="white"
                                         indeterminate
                    ></v-progress-circular>
                </div>
                <div @click="showChangeDialog">
                    <v-icon>mdi-pencil</v-icon>
                </div>
            </v-card-title>
        </v-img>
    </v-card>
</template>

<script>
import {backendUrl} from "@/plugins/axios";

export default {
    name: "SpecterCard",
    props: ['specter', 'index', 'isRemoteAuthentificated'],
    data() {
        return {
            serverLoading: false,
            hover: false
        }
    },
    mounted() {
        if (this.specter.preview === "") {
            this.$socket.emit('preview', {data: {specterId: this.specter.id}})
        }
    },
    computed: {
        getServerLaunchParameter() {
            return Number(process.env.VUE_APP_PI_VERSION)
        }
    },
    methods: {
        choose() {
            this.$emit('choose', this.specter.id)
        },
        showDeleteDialog() {
            this.$emit('showDeleteDialog', this.specter)
        },
        showInformationDialog() {
            this.$emit('showInformationDialog', this.specter)
        },
        showChangeDialog() {
            this.$emit('showChangeDialog', this.specter)
        },
        downloadSpecter(specter) {
            let data = `${backendUrl}/specter/download/${specter.id}`;
            const item = document.createElement('a');
            item.download = 'file.zip';
            item.href = data;

            document.body.appendChild(item);
            item.click();
            document.body.removeChild(item);
        },
        uploadSpecter(specter) {
            this.serverLoading = true
            this.$store.dispatch('uploadOnServer', {
                id: specter.id,
                endpoint: 'specter'
            })
                .then(() => this.serverLoading = false)
                .catch(() => this.serverLoading = false)
        }
    }

}
</script>

<style scoped>
.card {
    margin: 10px;
    background-color: #4a66d2;
}

.card-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0;
    position: absolute;
    width: 85%;
}

.animated-text {
    overflow: visible !important;
    animation: left-right 10s infinite linear;
    /*animation-delay: 0;*/
}

@keyframes left-right {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-100%);
    }
}

.image-trigger {
    width: 100%;
    height: 60%;
}

.cursor-pointer {
    cursor: pointer;
}

.id-span {
    display: block;
    color: white;
    position: absolute;
    z-index: 999;
}
</style>
