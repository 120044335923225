<template>
    <div ref="body" class="body">
        <div v-show="getCountOfGraphs" class="graph-view">
            <div class="graph-view__body flow-animation" v-bind:class="{'hide-window': showWindow}" @click.stop>
                <v-card v-for="graph in getGraphs" :key="graph.title" class="m-4" color="rgb(74, 102, 210)">
                    <v-img v-bind:src="graph.image">
                        <v-card-title class="d-flex justify-space-between pt-1" dense>
                            <v-spacer></v-spacer>
                            <div class="cursor-pointer shift-up" @click="deleteGraph(graph)">
                                <v-icon color="red" small>mdi-delete</v-icon>
                            </div>
                        </v-card-title>
                    </v-img>
                </v-card>
            </div>
            <div class="small-tongue cursor-pointer" @click.stop="onTongueClick">
                <v-icon v-bind:class="{'down-hover': showWindow, 'up-arrow': !showWindow}">mdi-download</v-icon>
            </div>
        </div>
        <div class="image-view">
            <canvas ref="canvas"></canvas>
        </div>
    </div>
</template>

<script>
import {fabric} from 'fabric'

export default {
    name: 'AdvancedImageView',
    props: ['stretchX', 'stretchY'],
    data() {
        return {
            showArrow: true,
            showWindow: true,
            pointId: 1,
            imageWidth: null,
            imageHeight: null,
            canvas: null,
            initialPersons: null,
            circles: [],
            graphs: [],
            currentCircle: new fabric.Circle({
                radius: 5,
                fill: 'white',
                strokeWidth: 2,
                stroke: 'black',
                hasBorders: false,
                hasControls: false,
                selectable: false,
                visible: false,
            }),
        }
    },
    mounted() {
        this.canvas = new fabric.Canvas(this.$refs.canvas, {
            width: this.calcCanvasWidth,
            height: this.calcCanvasHeight,
            backgroundColor: '#1e1e1e',
        })
        this.canvas.selection = false
        this.canvasSetup()

        document.addEventListener('keydown', this.resetZoom);
    },
    destroyed() {
        document.removeEventListener('keydown', this.resetZoom);
    },
    computed: {
        getImage() {
            return this.$store.getters.getImage
        },
        getGraphs() {
            return this.$store.getters.getGraphsList
        },
        getCountOfGraphs() {
            return this.$store.getters.getGraphsCount
        },
        getPoints() {
            return this.$store.getters.getPoints
        },
        isDrawningMode() {
            return this.$store.getters.isDrawningMode
        },
        calcCanvasWidth() {
            return this.$parent.$el.clientWidth
        },
        calcCanvasHeight() {
            return this.$parent.$el.clientHeight - 48
        },
    },
    watch: {
        getImage(value) {
            this.showImage(value)
        },
        isDrawningMode(value) {
            this.canvas.setViewportTransform([1, 0, 0, 1, 0, 0])
            this.currentCircle.visible = value
        },
        stretchX() {
            this.showImage(this.getImage)
        },
        stretchY() {
            this.showImage(this.getImage)
        },
        getGraphs(newGrpahs) {
            if (this.graphs.length !== 0) {
                this.removeGraphs()
            }
            this.graphs = []
            Object.entries(newGrpahs).forEach(([key, graph], pIindex) => {
                if (graph.visible) {
                    Object.entries(graph.points).forEach(([key, point]) => {
                        const x = Math.round(point.x * this.aspectRatioX) + this.leftShift - 5
                        const y = Math.round(point.y * this.aspectRatioY) + this.topShift - 5
                        const circle = new fabric.Circle({
                            radius: 5,
                            fill: 'white',
                            strokeWidth: 2,
                            stroke: 'black',
                            top: y,
                            left: x,

                            hasBorders: false,
                            hasControls: false,
                            selectable: false,
                        })
                        const text = new fabric.Text(String(point.id), {
                            fontFamily: 'Calibri',
                            fill: 'white',
                            stroke: 'black',
                            strokeWidth: 2,
                            top: this.calculateYforText(y),
                            left: this.calculateXforText(x)
                        });
                        const group = new fabric.Group([circle, text]);
                        this.graphs.push(group)
                        this.canvas.add(group)
                    })
                }
            })
        },
        getPoints(newCircles) {
            if (this.circles.length !== 0) {
                this.removeCircles()
            }
            this.circles = []
            Object.entries(newCircles).forEach(([key, value], pIindex) => {
                const x = Math.round(value.x * this.aspectRatioX) + this.leftShift - 5
                const y = Math.round(value.y * this.aspectRatioY) + this.topShift - 5
                const circle = new fabric.Circle({
                    radius: 5,
                    fill: 'white',
                    strokeWidth: 2,
                    stroke: 'black',
                    top: y,
                    left: x,

                    hasBorders: false,
                    hasControls: false,
                    selectable: false,
                })
                const text = new fabric.Text(String(value.id), {
                    fontFamily: 'Calibri',
                    fill: 'white',
                    stroke: 'black',
                    strokeWidth: 2,
                    top: this.calculateYforText(y),
                    left: this.calculateXforText(x)
                });
                const group = new fabric.Group([circle, text]);
                this.circles.push(group)
                this.canvas.add(group)
            })
        }
    },
    methods: {
        removeCircles() {
            this.circles.forEach((circle) => this.canvas.remove(circle))
        },
        removeGraphs() {
            this.graphs.forEach((graph) => this.canvas.remove(graph))
        },
        showImage(image) {
            fabric.Image.fromURL(image, (imgObg) => {
                this.imageWidth = imgObg.width
                this.imageHeight = imgObg.height

                const widthAspectRatio = this.calcCanvasWidth / imgObg.width
                const heightAspectRatio = this.calcCanvasHeight / imgObg.height

                this.aspectRatioX = 1
                this.aspectRatioY = 1
                if (this.stretchY) {
                    this.aspectRatioY = heightAspectRatio
                }
                if (this.stretchX) {
                    this.aspectRatioX = widthAspectRatio

                }

                const finalHeight = imgObg.height * this.aspectRatioY
                const finalWidth = imgObg.width * this.aspectRatioX

                this.topShift = 0
                if (this.calcCanvasHeight > finalHeight) {
                    this.topShift =
                        (Math.round(this.calcCanvasHeight) - Math.round(finalHeight)) / 2
                }

                this.leftShift = 0
                if (this.calcCanvasWidth > finalWidth) {
                    this.leftShift =
                        (Math.round(this.calcCanvasWidth) - Math.round(finalWidth)) / 2
                }

                this.canvas.setBackgroundImage(
                    imgObg,
                    this.canvas.renderAll.bind(this.canvas),
                    {
                        left: this.leftShift,
                        top: this.topShift,
                        scaleX: this.aspectRatioX,
                        scaleY: this.aspectRatioY,
                    }
                )
            })

        },
        resetZoom(event) {
            if (event.code === 'Escape') {
                this.canvas.setViewportTransform([1, 0, 0, 1, 0, 0]);
            }
        },
        canvasSetup() {
            const _this = this
            const zoomCoef = typeof InstallTrigger !== 'undefined' ? 0.989 : 0.9993
            console.log('[INFO] Setting up canvas event')
            // get it from http://fabricjs.com/fabric-intro-part-5#pan_zoom
            const canvas = this.canvas
            this.canvas.on('mouse:wheel', function (opt) {
                if (this.isDrawningMode)
                    return
                const delta = opt.e.deltaY
                let zoom = canvas.getZoom()
                zoom *= zoomCoef ** delta
                if (zoom > 10) zoom = 10
                if (zoom < 0.01) zoom = 0.01
                canvas.zoomToPoint({x: opt.e.offsetX, y: opt.e.offsetY}, zoom)
                opt.e.preventDefault()
                opt.e.stopPropagation()
            })

            const currentCircle = this.currentCircle
            this.canvas.on('mouse:down', function (opt) {
                const evt = opt.e
                if (evt.ctrlKey === false && _this.isDrawningMode) {
                    const pointerCoords = canvas.getPointer(evt)
                    currentCircle.top = pointerCoords.y - 5
                    currentCircle.left = pointerCoords.x - 5
                    currentCircle.setCoords()

                    const x = (pointerCoords.x - _this.leftShift) / _this.aspectRatioX
                    const y = (pointerCoords.y - _this.topShift) / _this.aspectRatioY

                    _this.$store.commit('setCurrentPointCoordinate', {
                        x: Math.round(x), y: Math.round(y),
                    })
                } else if (evt.ctrlKey === true && !_this.isDrawningMode) {
                    this.isDragging = true
                    this.lastPosX = evt.clientX
                    this.lastPosY = evt.clientY
                }
            })

            this.canvas.on('mouse:move', function (opt) {
                if (this.isDragging) {
                    const e = opt.e
                    const vpt = this.viewportTransform
                    vpt[4] += e.clientX - this.lastPosX
                    vpt[5] += e.clientY - this.lastPosY
                    this.requestRenderAll()
                    this.lastPosX = e.clientX
                    this.lastPosY = e.clientY
                }
            })
            this.canvas.on('mouse:up', function (opt) {
                // on mouse up we want to recalculate new interaction
                // for all objects, so we call setViewportTransform
                this.setViewportTransform(this.viewportTransform)
                this.isDragging = false
            })

            this.canvas.on({
                'object:moving'(e) {
                    e.target.opacity = 0.5
                },
                'object:modified'(e) {
                    e.target.opacity = 1
                },
            })
            this.canvas.on('')

            this.canvas.add(this.currentCircle)
        },
        calculateXforText(x) {
            if (x - 25 > 0) {
                return x - 30
            } else {
                return x + 3
            }
        },
        calculateYforText(y) {
            if (y - 40 > 0) {
                return y - 43
            } else {
                return y + 3
            }
        },
        onTongueClick() {
            this.showWindow = !this.showWindow
            if (!this.showWindow) {
                console.log('add')
                document.addEventListener('click', this.toggleGraphView, {once: true})
            } else {
                document.removeEventListener('click', this.toggleGraphView)
            }
        },
        toggleGraphView(e) {
            const container = document.getElementById('graph-view')
            if (container !== e.tagert)
                this.showWindow = !this.showWindow
        },
        deleteGraph(graph) {
            this.$store.commit('deleteGraph', graph)
        }
    }
}
</script>

<style lang="scss" scoped>
.body {
    position: relative;
    display: flex;
    justify-content: center;
    flex-grow: 1;
    background-color: #96A7E9;
}

.image-view {
    position: fixed;
    //display: flex;
    //flex-grow: 1;
    //width: 100%;
    //height: 100%;
    //justify-content: center;
}

.stretch-y img {
    height: 100%;
}

.stretch-x img {
    width: 100%;
}

.image-view img {
    align-self: center;
}

.graph-view {
    position: absolute;
    width: 2%;

    display: flex;
    align-items: center;
    flex-direction: column;

    z-index: 1;
}

.hide-window {
    height: 0px !important;
}

.small-tongue {
    background-color: #313f8a;
    padding: 3px 7px 7px 7px;
    border-radius: 0 0 15px 15px;
}

.down-hover:hover {
    padding-top: 15px;
}

.up-arrow {
    transform: rotate(180deg);
    transition: all 1s;
}

.graph-view__body {
    display: flex;
    flex-direction: column;

    z-index: 3;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
    background-color: #313f8a;

    overflow-y: scroll;

    height: 500px;
    border-radius: 0 0 15px 15px;
}

.flow-animation {
    transition: all 1s;
}

.cursor-pointer {
    cursor: pointer;
}

.cursor-crosshair {
    cursor: crosshair;
}

.shift-up {
    top: -10px;
}

.absolute-fill-space {
    position: absolute;
    max-height: 100%;
}

</style>
