<template>
    <v-card v-show="!isAuthorized" class="mb-2" color="secondary">
        <v-card-title>
            {{ $t('videoUploader/authTitle') }}
        </v-card-title>
        <v-card-text @keyup.enter="login">
            <v-form ref="form" v-model="valid" class="login-form" lazy-validation>
                <v-text-field id="login-field"
                              v-model="username"
                              autofocus
                              color="#4a66d2"
                              dark
                              filled
                              label="Username: "
                              required
                ></v-text-field>
                <v-text-field id="password-field"
                              v-model="password"
                              :append-icon="isShowed ? 'mdi-eye' : 'mdi-eye-off'"
                              :type="isShowed ? 'text' : 'password'"
                              color="#4a66d2"
                              dark
                              filled
                              label="Password: "
                              required
                              @click:append="isShowed = !isShowed">
                </v-text-field>
                <v-btn id="login-button" color="success" @click="login">Sign in</v-btn>
            </v-form>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    name: "OdmAuthorization",
    data() {
        return {
            valid: true,
            isShowed: false,
            username: '',
            password: '',
        }
    },
    mounted() {
        this.$store.dispatch('checkODMAuth')
    },
    computed: {
        isAuthorized() {
            return this.$store.getters.isODMAuthorized
        }
    },
    methods: {
        login() {
            console.log('here')
            const user = {username: this.username, password: this.password};
            this.$store.dispatch('authODMRequest', user)
        }
    }
}
</script>

<style scoped>

</style>
