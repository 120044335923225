<template>
    <v-dialog v-model="changeDialog" class="change-dialog" width="500px">
        <v-card color="secondary">
            <v-card-title class="d-flex flex-column">{{ $t('specterUpdate/title') }}</v-card-title>
            <div class="d-flex flex-row">
                <v-spacer></v-spacer>
                {{ $t('specterUpdate/modifyInfo') }}
                <v-switch v-model="changeOrientationMode" class="pl-2" color="white" dense hide-details></v-switch>
                {{ $t('specterUpdate/modifySpecter') }}
                <v-spacer></v-spacer>
            </div>
            <v-card-text v-if="! changeOrientationMode">
                <v-text-field
                    v-model="name"
                    :label="$t('specterUpdate/name')">
                </v-text-field>
                <v-text-field
                    v-model="r"
                    :hint="'(1 - ' + upBorder + ')'"
                    :label="$t('specterUpdate/red')"
                    :rules="[rules.checkBorder]" class="no-select pt-2 pb-2"
                    clearable
                    color="white"
                    dense>
                </v-text-field>
                <v-text-field
                    v-model="g"
                    :hint="'(1 - ' + upBorder + ')'"
                    :label="$t('specterUpdate/green')"
                    :rules="[rules.checkBorder]" class="no-select pt-2 pb-2"
                    clearable
                    color="white"
                    dense>
                </v-text-field>
                <v-text-field
                    v-model="b"
                    :hint="'(1 - ' + upBorder + ')'"
                    :label="$t('specterUpdate/blue')"
                    :rules="[rules.checkBorder]" class="no-select pt-2 pb-2"
                    clearable
                    color="white"
                    dense>
                </v-text-field>
                <v-textarea v-model="description" :label="$t('specterUpdate/description')" class="pt-0"
                            rows="1"></v-textarea>
                <span class="d-flex flex-row">
                    {{ $t('specterUpdate/visibility') }}:
                    <v-switch v-model="visible" class="pl-2" color="blue"></v-switch>
                </span>

            </v-card-text>

            <v-card-text v-if="changeOrientationMode">
                <v-select v-model="choosenAction" :items="Object.keys(actions)" :label="$t('specterUpdate/action')"
                          color="white"></v-select>
                <v-select v-model="choosenAxis" :items="axes" :label="$t('specterUpdate/axis')" color=white></v-select>
                <v-select v-if="choosenAction === 'Rotate'" v-model="degree"
                          :items="availableDegrees" :label="$t('specterUpdate/degree')"
                          color="white"></v-select>
            </v-card-text>

            <v-card-actions>
                <v-btn color="error" v-on:click="changeDialog = false">Close</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="success" v-on:click="saveChanges()">Save</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "SpecterSettingView",
    props: {
        showDialog: Boolean,
    },
    watch: {
        'showDialog': function () {
            this.openDialog();
            this.changeDialog = true;
        }
    },
    data() {
        return {
            error_in_settings: false,
            rules: {
                maxCharacterLength: value => {
                    if (value.length < 19) {
                        this.error_in_settings = false;
                        return true;
                    }
                    this.error_in_settings = true;
                    return "Max 18 characters"
                },
                checkBorder: value => {
                    if (value > 0 && value <= this.$store.getters.getCurrentSpecter.layers.length) {
                        this.error_in_settings = false;
                        return true;
                    }
                    this.error_in_settings = true;
                    return this.$i18n.messages[this.$i18n.locale]['layerNumberError']
                }
            },
            actions: {
                'Rotate': 'rotate',
                'Flip': 'flip'
            },
            choosenAction: 'Flip',
            axes: ['X', 'Y', 'Z'],
            availableDegrees: ["90", "180", "270"],
            choosenAxis: 'X',
            degree: 0,
            changeOrientationMode: false,

            name: '',
            r: '',
            g: '',
            b: '',
            description: '',
            visible: false,

            downBorder: 0,
            upBorder: 0,

            changeDialog: false
        }
    },
    methods: {
        openDialog: function () {
            const currentSpecter = this.$store.getters.getCurrentSpecter;
            this.name = currentSpecter.name;
            this.r = currentSpecter.r;
            this.g = currentSpecter.g;
            this.b = currentSpecter.b;
            this.description = currentSpecter.description;
            this.visible = currentSpecter.visible;

            this.upBorder = currentSpecter.layers.length;
        },
        saveChanges: function () {
            const changes = {
                'name': this.name ? this.name : '',
                'description': this.description,
                'r': this.r,
                'g': this.g,
                'b': this.b,
                'visible': this.visible,
                'new_specter': this.changeOrientationMode,
                'action': this.actions[this.choosenAction],
                'axis': this.choosenAxis,
                'degree': this.degree
            };
            this.changeDialog = false
            this.$store.dispatch('updateHyperspecter', changes)
        },
    }
}
</script>

<style scoped>

</style>
