import backend from '../plugins/axios'

export function login(user) {
    return backend.post('/auth/login', user)
}

export function logout() {
    return backend.post('/auth/logout')
}

export function odmLogin(user) {
    return backend.post('/auth/odm', user)
}

export function odmAuthStatus() {
    return backend.get('/auth/odm')
}
