/* eslint-disable no-unused-vars */
import axios from "../axios";
import {login, logout, odmAuthStatus, odmLogin} from "@/api/AuthAPI";

export const vuexAuthModule = {
    state: {
        token: localStorage.getItem('user-token') || '',
        authStatus: '',
        odmAuthorized: false,
    },
    getters: {
        // "foo"       Evaluates to "foo".
        // !"foo"      Evaluates to false.
        // !!"foo"     Evaluates to true.
        isAuthenticated: state => !!state.token,
        isODMAuthorized: state => state.odmAuthorized
    },
    mutations: {
        authRequest(state) {
            state.status = 'loading';
        },
        authSuccess(state) {
            state.status = 'success';
        },
        authError(state) {
            state.status = 'error';
        },
        authLogout(state) {
            state.token = '';
            state.status = 'unauthorized'
        },
        setODMAuthStatus(state, status) {
            state.odmAuthorized = status
        }
    },
    actions: {
        checkODMAuth({commit, dispatch}) {
            return new Promise(((resolve, reject) => {
                odmAuthStatus()
                    .then(resp => {
                        commit('setODMAuthStatus', true)
                        resolve(resp)
                    })
                    .catch(err => {
                        commit('setODMAuthStatus', false)
                        reject(err)
                    })
            }))

        },
        authODMRequest({commit, dispatch}, user) {
            return new Promise(((resolve, reject) => {
                odmLogin(user)
                    .then(resp => {
                        commit('setODMAuthStatus', true)
                        resolve(resp)
                    })
                    .catch(err => {
                        commit('setODMAuthStatus', false)
                        reject(err)
                    })
            }))
        },
        authRequest({commit, dispatch}, user) {
            return new Promise((resolve, reject) => {
                commit('authRequest');
                login(user)
                    .then(resp => {
                        console.log(resp)
                        localStorage.setItem('username', user.username);
                        localStorage.setItem('role', resp.data.user.role);

                        commit('authSuccess');
                        resolve(resp);
                    })
                    .catch(err => {
                        commit('authError', err);
                        localStorage.removeItem('user-token');
                        reject(err);
                    })
            })
        },
        authLogout({commit, dispatch}) {
            return new Promise(((resolve, reject) => {
                logout()
                    .then(resp => {
                        commit('authLogout');
                        localStorage.removeItem('username');
                        resolve();
                    })
                    .catch(err => {
                        commit('authError', err);
                        reject(err);
                    })
            }))
        }
    },
};
