<template>
    <v-menu :close-on-click="true" :close-on-content-click="false" offset-y>
        <template v-slot:activator="{ on }">
            <v-btn v-on="on" class="mr-2 ml-1" text>
                <v-icon>mdi-earth</v-icon>
            </v-btn>
        </template>
        <v-list class="align-center" color="secondary">
            <v-list-item class="mb-2">
                <span class="text-center font-weight-bold title">
                    {{ $t('langDialog/choose') }}
                </span>
            </v-list-item>
            <v-list-item v-for="lang in languages" v-bind:key="lang" v-model="locale" class="justify-content-center">
                <v-btn v-on:click="locale = lang">{{ lang }}</v-btn>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
export default {
    name: "ChangeLanguageView",
    data() {
        return {
            languages: ['en', 'ru'],
            locale: this.$locale
        }
    },
    watch: {
        locale(lang) {
            localStorage.setItem('locale', lang)
            this.$locale = lang;
            this.$root.$i18n.locale = lang;
        }
    },
}
</script>

<style scoped>

</style>
