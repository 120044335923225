import {store} from "@/plugins/vuex";
import LayerFactory from "@/model/Layer";

export default addLayer

function addLayer(layer_config, layer_type) {
    switch (layer_type) {
        case 'INDI':
            add_INDI(layer_config)
            break
        case 'PCA':
            add_PCA(layer_config)
            break
        case 'KMEANS':
            add_KMEANS(layer_config)
            break
    }
}

function add_INDI(layer_config) {
    const layer_name = layer_config.name
    const first_layer = layer_config.l1
    const second_layer = layer_config.l2
    const layer1 = new LayerFactory().createLayer('ndi', {layer_name, first_layer, second_layer})
    const layer2 = new LayerFactory().createLayer('ndi', {layer_name, first_layer, second_layer})

    layer1.layer_slider = layer_config.class_1
    layer1.apply_threshold = true
    layer1.layer_color = '#FF0000'
    layer2.layer_slider = layer_config.class_2
    layer2.apply_threshold = true
    layer2.layer_color = '#00FF00'
    store.commit('addLayer', layer1)
    store.commit('addLayer', layer2)
}

function add_PCA(layer_config) {
    const layer_name = 'PCA'
    const layer_id = -1
    const layer = new LayerFactory().createLayer('pca', {layer_id, layer_name})
    store.commit('addLayer', layer)
}

function add_KMEANS(layer_config) {
    const clusters = layer_config.clusters
    const layer_name = `Kmeans ${clusters}`
    const layer = new LayerFactory().createLayer('kmeans', {clusters, layer_name})
    store.commit('addLayer', layer)
}
