/* eslint-disable no-unused-vars */
import {
    deleteHyperspecter,
    getHyperspecterList,
    getLayers,
    updateHyperspecter,
    uploadNewHyperspecter, uploadVideo
} from "../../api/DataAPI";
import b64toBlob from "../../service/ConvertorsService";
import {i18n} from "@/plugins/translations";
import Vue from "vue";


export const vuexDataProviderModule = {
    state: {
        specters: [],
        layers: [],
        image: '',
        currentSpecter: null,

        divider: 1,
    },
    getters: {
        getSpecters: state => state.specters,

        getLayers: state => state.layers,

        getImage: state => state.image,

        getCurrentSpecter: state => state.currentSpecter,

        getDivider: state => state.divider,
    },
    mutations: {
        SOCKET_preview(state, payload) {
            const image = URL.createObjectURL(b64toBlob(payload.buffer), {type: "image/webp"})
            const index = state.specters.findIndex(element => element.id === payload.id)
            Vue.set(state.specters[index], 'preview', image)
        },
        addLayer(state, layer) {
            state.layers.push(layer);
        },
        removeLayer(state, layerIndex) {
            state.layers.splice(layerIndex, 1);
        },
        setLayers(state, layers) {
            state.layers = layers;
        },
        clearLayersList(state) {
            state.layers = []
        },
        clearSpecterList(state) {
            state.specters = []
        },
        clearImage(state) {
            URL.revokeObjectURL(state.image);
        },
        setImage(state, image) {
            if (state.image != null) {
                URL.revokeObjectURL(state.image);
            }
            state.image = URL.createObjectURL(b64toBlob(image), {type: "image/png"})
        },
        setDivider(state, divider) {
            state.divider = divider;
        },
        setChoosenSpecterById(state, specterId) {
            state.currentSpecter = state.specters.filter(specter => specter.id === specterId)[0];
        },
        setSpecterList(state, specters) {
            specters.sort((a, b) => {
                if (a.id < b.id) {
                    return 1
                }
                if (a.id > b.id) {
                    return -1
                }
                return 0
            })
            state.specters = specters;
        },
        deleteSpecter(state, specterForDelete) {
            state.specters = state.specters.filter(specter => specter.id !== specterForDelete.id)
        },
    },
    actions: {
        fetchNewData({commit, dispatch}) {
            commit('clearSpecterList')
            commit('clearLayersList')
            return new Promise((resolve, reject) => {
                getHyperspecterList()
                    .then(response => {
                        let specterList = JSON.parse(response.data['data'])
                        commit('setSpecterList', specterList);
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    })
            })
        },
        updateHyperspecter({commit, state}, changes) {
            return new Promise(((resolve, reject) => {
                updateHyperspecter(state.currentSpecter.id, changes)
                    .then(response => {
                        this._vm.$toast.info(i18n.t("notification/updatedSpecter"))
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    })
            }))
        },
        deleteHyperspecter({commit, dispatch}, specterForDelete) {
            return new Promise(((resolve, reject) => {
                deleteHyperspecter(specterForDelete.id)
                    .then(response => {
                        this._vm.$toast.info(i18n.t("notification/deletedSpecter"))
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    });
            }))
        },
        uploadHyperspecter({commit, dispatch}, formData) {
            return new Promise((resolve, reject) => {
                uploadNewHyperspecter(formData)
                    .then(response => {
                        this._vm.$toast.info(i18n.t("notification/specterIsAdded"))
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    })
            })
        },
        uploadVideo({commit}, formData) {
            return new Promise(((resolve, reject) => {
                uploadVideo(formData)
                    .then(response => {
                        this._vm.$toast.info(i18n.t("notification/videoIsProcessing"))
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error);
                    })
            }))
        },
        fetchLayers({commit, dispatch}, payload) {
            return new Promise((resolve, reject) => {
                const specterId = payload.specterId;
                const requestObject = payload.params;
                getLayers(specterId, requestObject)
                    .then(response => {
                        commit('clearImage');
                        commit('setImage', response.data.img);
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    })
            })
        },
    },
};
