<template>
    <v-card light min-height="100%">
        <v-card-title>Занятое место:</v-card-title>
        <v-card-text>
            <v-progress-linear :color="textColor" :value="getUsagePercent" height="25">
                <template v-slot="">
                    <strong>{{ getMemoryStatus }}</strong>
                </template>
            </v-progress-linear>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    name: "MemoryManagement",
    data() {
        return {}
    },
    mounted() {
        this.$store.dispatch('fetchMemoryStatus')
    },
    computed: {
        getUsagePercent() {
            return this.$store.getters.getUsagePercent
        },
        getMemoryStatus() {
            return this.$store.getters.getMemoryStatus
        },
        textColor() {
            if (this.getUsagePercent <= 60) {
                return 'green'
            } else if (this.getUsagePercent >= 80) {
                return 'red'
            } else {
                return '#b3a104'
            }
        },
    }
}
</script>

<style scoped>

</style>
