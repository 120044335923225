<template>
    <div>
        <transition name="scroll-x-transition">
            <div class="d-flex flex-column position-absolute">
                <v-btn v-show="!sidebar"
                       class="m-3 mb-0 btn-bring-up low-opacity normal-opacity-hover no-focus" color="secondary" dark
                       fab
                       @click="sidebar = !sidebar">
                    <v-icon dark>mdi-cogs</v-icon>
                </v-btn>
                <v-btn v-show="!sidebar"
                       class="m-3 mb-0 btn-bring-up low-opacity normal-opacity-hover no-focus" color="success" dark
                       fab
                       @click="newLayerDialog">
                    <v-icon dark>mdi-plus</v-icon>
                </v-btn>
                <v-btn v-show="!sidebar"
                       class="m-3 btn-bring-up low-opacity normal-opacity-hover no-focus" color="secondary" dark
                       fab
                       @click="saveImage">
                    <v-icon dark>mdi-content-save</v-icon>
                </v-btn>
            </div>
        </transition>

        <new-layer-dialog :showDialog="showNewLayerDialog" :upBorder="upBorder"
                          @closeDialog="closeDialog"></new-layer-dialog>

        <v-navigation-drawer v-model="sidebar" absolute class="sidebar transparent-background" hide-overlay
                             overlay-color="white" temporary>
            <v-list class="sidebar__list-layer" dense nav>
                <card-view
                    v-for="layer in getLayers"
                    :key="layer.name"
                    v-bind:layer="layer"
                    @delete-layer="deleteLayer"
                    @reset-timer="resetTimer"
                ></card-view>
            </v-list>
        </v-navigation-drawer>
    </div>
</template>

<script>
import CardView from "./CardView";
import {Layer} from "@/model/Layer";
import NewLayerDialog from "@/components/layers-view/NewLayerDialog";

export default {
    name: "SettingsView",
    components: {
        CardView,
        NewLayerDialog
    },
    data() {
        return {
            sidebar: false,
            showNewLayerDialog: false,

            oldLayers: null,
            upBorder: 0,
            divider: 1,
            timerInterval: 2
        };
    },
    computed: {
        getLayers() {
            return this.$store.getters.getLayers;
        },
        getClusterLayers() {
            return this.$store.getters.getClusterLayers;
        },
        getImage() {
            return this.$store.getters.getImage
        },
        getClusterLayer() {
            return this.$store.getters.getClusterLayers
        },
        getSpecter() {
            return this.$store.getters.getCurrentSpecter;
        },
    },
    created: function () {
        if (this.getLayers.length === 0) {
            this.$router.push({name: "AdvancedHyperspecterView"});
            return;
        }

        this.upBorder = this.$store.getters.getCurrentSpecter.layers.length;
        this.fetchNewData();
        this.oldLayers = JSON.stringify(this.getLayers);
        this.resetTimer();
    },
    destroyed: function () {
        clearInterval(this.timer);
    },
    methods: {
        closeDialog() {
            this.showNewLayerDialog = false
        },
        newLayerDialog() {
            if (process.env.VUE_APP_PI_VERSION === 'true') {
                const layer_name = this.computedLayerName(1);
                const layer = new Layer(1, layer_name, '#FFFFFF', false, [100, 255], false);
                this.$store.commit('addLayer', layer);
            } else {
                this.showNewLayerDialog = true
            }
        },
        computedLayerName(layer_id) {
            const layerNumber = Number(layer_id);
            let cur_layer = this.getSpecter['layers'][layerNumber];
            if (cur_layer !== undefined)
                return cur_layer['name'];
            else
                return 'Unknown layer'
        },
        deleteLayer: function (item) {
            if (this.getLayers.length !== 1) {
                const index = this.getLayers.indexOf(item);
                if (index > -1) {
                    this.$store.commit("removeLayer", index);
                }
            }
        },
        autoRefresh: function () {
            if (this.oldLayers !== JSON.stringify(this.getLayers)) {
                this.oldLayers = JSON.stringify(this.getLayers);
                this.fetchNewData();
            }
        },
        resetTimer: function () {
            clearInterval(this.timer);
            this.timer = setInterval(() => {
                this.autoRefresh();
            }, this.timerInterval * 1000);
        },
        fetchNewData: function () {
            if (this.error_in_settings) {
                return;
            }
            this.isLoading = true;
            const requestObject = {
                layers: this.getLayers,
                divider: this.$store.getters.getDivider
            };
            const payload = {
                specterId: this.$store.getters.getCurrentSpecter.id,
                params: requestObject
            };
            this.$store
                .dispatch("fetchLayers", payload)
        },
        saveImage() {
            const data = this.$store.getters.getImage;
            const item = document.createElement('a');
            item.download = getCurTime();
            item.href = data;

            document.body.appendChild(item);
            item.click();
            document.body.removeChild(item);
        }
    }
};

function getCurTime() {
    const curDate = new Date();
    return curDate.getDate().toString() + "/"
        + (curDate.getMonth() + 1).toString() + "/"
        + curDate.getFullYear().toString() + "@"
        + curDate.getHours().toString() + "-"
        + curDate.getMinutes().toString() + "-"
        + curDate.getSeconds().toString() + ".png";
}
</script>

<style lang="scss" scoped>
$secondary-color: #313f8a;
$primary-color: #4a66d2;

.btn-bring-up {
    z-index: 4;
}

.transparent-background {
    background: transparent;
}

.low-opacity {
    opacity: .7;
}

.normal-opacity-hover:hover {
    opacity: 1;
}

.no-focus:focus {
    border: 0;
    outline: 0;
}
</style>
