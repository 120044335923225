import axios from 'axios'
import {router} from './route'
import {store} from './vuex'
import {i18n} from "@/plugins/translations";
import Vue from "vue";

axios.defaults.withCredentials = true

let baseURL;
let backendUrl;
let cameraBackendUrl;
let remoteServerUrl;
if (process.env.NODE_ENV === 'development') {
    baseURL = location.protocol + '//' + location.hostname + ':' + 51117
    backendUrl = baseURL
    cameraBackendUrl = location.protocol + '//' + location.hostname + ':' + 8000
    remoteServerUrl = 'https://hyperspectrum.copter.space/api'
} else {
    baseURL = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '')
    backendUrl = baseURL + '/api'
    cameraBackendUrl = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '') + '/camera'
    remoteServerUrl = 'https://hyperspectrum.copter.space/api'
}

const backend = axios.create({
    baseURL: backendUrl,
})
const camera_backend = axios.create({
    baseURL: cameraBackendUrl
})
const remote_server = axios.create({
    baseURL: remoteServerUrl
})

backend.defaults.withCredentials = true
remote_server.defaults.withCredentials = true

if (!Number(process.env.VUE_APP_PI_VERSION)) {
    backend.interceptors.response.use(
        (response) => {
            return response
        }, (error) => {
            if (error.response && error.response.status === 401) {
                store.commit('authLogout')
                router.push({path: 'auth'})
                Vue.$toast.error(i18n.t('notification/erroneousLogin'));
            } else {
                if (error.response.hasOwnProperty('data') && error.response.data.hasOwnProperty('message')) {
                    console.error(error.response.data.message)
                    Vue.$toast.error(error.response.data.message);
                } else {
                    Vue.$toast.error(error.response);
                }

            }
            return Promise.reject(error)
        }
    )
}

export default backend
export {camera_backend, backendUrl, baseURL, remote_server}
