<template>
    <v-data-table
        v-model="selected"
        :headers="specterHeaders"
        :items="specters"
        :search="search"
        light
        show-expand
        show-select
        single-expand
    >
        <template v-slot:top>
            <v-toolbar flat>
                <v-toolbar-title>Specters</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    color="white"
                    hide-details
                    label="Search"
                    single-line
                ></v-text-field>
                <v-spacer></v-spacer>
                <v-btn text @click="initialize">
                    <v-icon>mdi-refresh</v-icon>
                </v-btn>
                <v-btn :disabled="selected.length === 0" text @click="deleteSelected">
                    <v-icon color="red">mdi-delete</v-icon>
                </v-btn>
                <v-dialog v-model="dialog" max-width="500px">
                    <v-card>
                        <v-card-title>
                            <span class="text-h5">Edit Item</span>
                        </v-card-title>
                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field
                                            v-model="editedItem.name"
                                            label="Specter name"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" md="4" sm="6">
                                        <v-text-field
                                            v-model="editedItem.author"
                                            label="Author"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="4" sm="6">
                                        <v-text-field
                                            v-model="editedItem.type"
                                            label="Type"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="4" sm="6">
                                        <v-switch v-model="editedItem.visible" label="Visible"></v-switch>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="close">
                                Cancel
                            </v-btn>
                            <v-btn color="blue darken-1" text @click="save">
                                Save
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="dialogDelete" max-width="500px">
                    <v-card>
                        <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                            <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-toolbar>
        </template>
        <template v-slot:item.visible="{ item }">
            <v-simple-checkbox
                v-model="item.visible"
                disabled
            ></v-simple-checkbox>
        </template>
        <template v-slot:item.actions="{ item }">
            <v-icon class="mr-2" small @click="editItem(item)">
                mdi-pencil
            </v-icon>
            <v-icon small @click="deleteItem(item)">
                mdi-delete
            </v-icon>
        </template>
        <template v-slot:no-data>
            <v-btn color="primary" @click="initialize">
                Reset
            </v-btn>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
                {{ item.description }}
            </td>
        </template>
    </v-data-table>
</template>

<script>
export default {
    name: "SpecterManagement",
    data() {
        return {
            selected: [],
            dialog: false,
            dialogDelete: false,
            search: '',
            isSpecterTableLoading: false,
            editedIndex: -1,
            editedItem: {
                name: '',
                author: '',
                type: '',
                visible: true,
            },
            defaultItem: {
                name: '',
                author: '',
                type: '',
                visible: true,
            },

            specters: [],
            specterHeaders: [
                {
                    text: 'ID',
                    align: 'start',
                    sortable: true,
                    value: 'id',
                },
                {text: 'Name', value: 'name', sortable: true},
                {text: 'Author', value: 'author', sortable: true},
                {text: 'Type', value: 'type', sortable: true},
                {text: 'Width', value: 'width', sortable: false},
                {text: 'Height', value: 'height', sortable: false},
                {text: 'Channels', value: 'layersCount', sortable: false},
                // {text: 'R', value: 'r', sortable: false},
                // {text: 'G', value: 'g', sortable: false},
                // {text: 'B', value: 'b', sortable: false},
                {text: 'Visible', value: 'visible', sortable: false},
                {text: 'Actions', value: 'actions', sortable: false},
                {value: 'data-table-expand'}
            ],
        }
    },
    mounted() {
        this.initialize()
    },
    computed: {
        getSpectersList() {
            return this.$store.getters.getSpecters
        },
    },
    methods: {
        initialize() {
            this.isSpecterTableLoading = true
            this.$store.dispatch('fetchNewData')
                .then(() => {
                    this.specters = this.getSpectersList
                })
                .finally(() => this.isSpecterTableLoading = false)
        },

        editItem(item) {
            this.editedIndex = this.specters.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialog = true
        },

        deleteItem(item) {
            this.editedIndex = this.specters.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialogDelete = true
        },

        deleteItemConfirm() {
            if (this.editedIndex === -1) {
                const specterIdList = []
                for (const selectedObject of this.selected) {
                    const id = selectedObject.id
                    specterIdList.push(id)
                }
                this.selected = []
                this.$store.dispatch('deleteSeveralSpecters', specterIdList)
                    .then(() => this.initialize())
            } else {
                const specterId = this.specters[this.editedIndex].id
                this.$store.dispatch('deleteSpecter', specterId)
                    .then(() => this.initialize())
            }
            this.closeDelete()
        },

        close() {
            this.dialog = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },

        closeDelete() {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },

        save() {
            const specterId = this.specters[this.editedIndex].id
            this.$store.dispatch('updateSpecter', {'id': specterId, 'data': this.editedItem})
                .then(() => this.initialize())
            this.close()
        },

        deleteSelected() {
            this.editedIndex = -1
            this.dialogDelete = true
        }
    }
}
</script>

<style scoped>

</style>
