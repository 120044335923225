<template>
    <v-app>
        <v-main>
            <div class="app">
                <transition mode="out-in" name="fade">
                    <router-view/>
                </transition>
            </div>
        </v-main>
    </v-app>
</template>

<script>
export default {
    name: 'App',
    data: () => ({}),

    created: function () {
        this.$locale = localStorage.getItem('locale') ? localStorage.getItem('locale') : 'en'
    }
};
</script>

<style lang="scss">
@import "./assets/css/boorstrap.min.css";
@import "./assets/css/googlefonts.css";

.v-application {
    background-color: #96A7E9 !important;
}

.app {
    height: 100%;
}

html {
    scrollbar-width: none;
}

body::-webkit-scrollbar {
    display: none;
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
}

.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
}

.fade-enter, .fade-leave-to {
    opacity: 0;
}
</style>
