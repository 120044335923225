<template>
    <v-card color="secondary">
        <v-card-title class="headline" primary-title>
            {{ $t('specterUploader/VideoTitle') }}
        </v-card-title>

        <v-card-text>
            <v-file-input id="video-input" :label="$t('specterUploader/VideoFile')"
                          accept=".avi,.mp4"
                          color="white"
                          counter
                          multiple
                          show-size @click.stop></v-file-input>
            <v-file-input id="gps-input" :label="$t('specterUploader/GpsFile')"
                          accept=".csv" color="white" @click.stop></v-file-input>
        </v-card-text>

        <v-card-actions>
            <span class="d-flex align-content-center pb-5 pl-5">{{ $t('specterUploader/visibility') }}:</span>
            <v-switch v-model="isVisible" class="pl-3" color="info"></v-switch>
        </v-card-actions>

        <v-card-actions>
            <v-btn color="red" @click="closeDialog">
                {{ $t('button/close') }}
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="success" @click="sendFiles">
                {{ $t('button/upload') }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
    name: "RpcamVideoUploader",
    data() {
        return {
            isLoading: false,
            isVisible: false,
        }
    },
    methods: {
        closeDialog() {
            this.$emit('close')
        },
        sendFiles: function () {
            const videoInput = document.getElementById('video-input');
            const gpsInput = document.getElementById('gps-input');
            if (!videoInput.files || videoInput.files.length === 0) {
                this.$toast.warning(this.$t('notification/ErrorNotEmptyField'));
                return;
            }
            // Cast from FileList to Array so I can use sort method
            const files = [].slice.call(videoInput.files)
            files.sort()
            console.log(files)

            let formData = new FormData();
            formData.append('author', localStorage.getItem('username'));
            formData.append('visible', this.isVisible);
            videoInput.files.forEach((file, index) => {
                formData.append(`video_${index}`, file)
            })
            if (gpsInput.files || gpsInput.files.length !== 0)
                gpsInput.files.forEach((file, index) => {
                    formData.append(`gps_${index}`, file);
                })

            this.isLoading = true;
            this.$store.dispatch('uploadVideo', formData)
                .then(() => {
                    this.$toast.info(this.$t('notification/filesSended'));
                    this.isLoading = 'false';
                })
                .catch(() => {
                    this.$toast.error(this.$t('notification/filesSendError'));
                    this.isLoading = 'red';
                })
            this.closeDialog()
        }
    }
}
</script>

<style scoped>

</style>
