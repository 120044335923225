<template>
    <div class="text-center">
        <h1>Service unavailable now. Please try later</h1>
    </div>
</template>

<script>
export default {
    name: "ServiceUnavailableView"
}
</script>

<style scoped>

</style>
