import backend from '../plugins/axios'

export function getAllUsers() {
    return backend.get('/admin/user')
}

export function updateUser(data) {
    return backend.put('/admin/user', data)
}

export function deleteUser(user_id) {
    return backend.delete(`/admin/user/${user_id}`)
}

export function addUser(data) {
    return backend.post('/admin/user', data)
}

export function deleteSpecter(specterId) {
    return backend.delete(`/admin/specter/${specterId}`)
}

export function deleteSeveralSpecters(data) {
    return backend.delete(`/admin/specter`, {data: data})
}

export function updateSpecter(specterId, data) {
    return backend.put(`/admin/specter/${specterId}`, data)
}
