<template>
    <v-card class="d-flex align-center flex-column" color="primary" flat>
        <v-card-title>{{ $t('newLayerDialog/ndiTitle') }}</v-card-title>
        <v-card-text class="w-50">
            <v-form ref="form" v-model="valid" class="d-flex flex-column justify-center">
                <v-text-field
                    v-model.number="firstLayer"
                    :hint="'(1 - ' + upBorder + ')'"
                    :label="$t('newLayerDialog/ndiL1')"
                    :rules="[rules.check_border]"
                    class="no-select"
                    clearable
                    color="white"
                    type="number">
                </v-text-field>
                <v-text-field
                    v-model.number="secondLayer"
                    :hint="'(1 - ' + upBorder + ')'"
                    :label="$t('newLayerDialog/ndiL1')"
                    :rules="[rules.check_border]"
                    class="no-select"
                    clearable
                    color="white"
                    type="number">
                </v-text-field>
            </v-form>
        </v-card-text>
        <v-card-actions class="w-100 justify-space-around">
            <v-btn color="error" @click="closeDialog">{{ $t('button/close') }}</v-btn>
            <v-btn :disabled="!valid" color="green" @click="addNewLayer()">
                {{ $t('newLayerDialog/addBtn') }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import LayerFactory from "@/model/Layer";

export default {
    name: "NdiLayer",
    props: ['upBorder'],
    data() {
        return {
            valid: true,
            rules: {
                check_clusters_count: value => value > 0 && value < 256,
                check_border: value => value > 0 && value <= this.upBorder
            },
            firstLayer: 1,
            secondLayer: 2
        }
    },
    methods: {
        closeDialog() {
            this.$emit('closeDialog')
        },
        addNewLayer() {
            const layer_name = `NDI ${this.firstLayer} ${this.secondLayer}`
            const first_layer = this.firstLayer
            const second_layer = this.secondLayer
            const layer = new LayerFactory().createLayer('ndi', {layer_name, first_layer, second_layer})
            this.$store.commit('addLayer', layer)

            this.closeDialog()
        }
    }
}
</script>

<style scoped>

</style>
