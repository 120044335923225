export default class LayerFactory {
    createLayer(type, {
                    layer_id = 1,
                    layer_name = 'unset',
                    layer_color = '#FFFFFF',
                    layer_clahe = false,
                    layer_slider = [100, 255],
                    layer_index = null,
                    threshold = false,
                    first_layer = 1,
                    second_layer = 2,
                    clusters = 4,
                    coordinates = [],
                    parameters = {},
                },
                ...args
    ) {
        let layer
        if (type === 'index') {
            layer = new IndexLayer(layer_name, layer_index, layer_color, parameters)
        } else if (type === 'kmeans') {
            layer = new KmeansLayer(layer_id, layer_name, clusters)
        } else if (type === 'ndi') {
            layer = new NdiLayer(layer_name, first_layer, second_layer)
        } else if (type === 'pca') {
            layer = new PcaLayer(layer_id, layer_name)
        } else if (type === 'plain') {
            if (args.length !== 0) {
                let [layer_id, layer_name, layer_color, layer_clahe, layer_slider, threshold] = args
                layer = new PlainLayer(layer_id, layer_name, layer_color, layer_clahe, layer_slider, threshold)
            } else {
                layer = new PlainLayer(layer_id, layer_name, layer_color, layer_clahe, layer_slider, threshold)
            }
        } else if (type === 'som') {
            layer = new SoMLayer(layer_name, coordinates)
        }

        layer.type = type
        layer.toString = function () {
            console.log(`Layer of type: ${this.type}`)
        }

        return layer
    }
}


class PlainLayer {
    layer_id;
    layer_name;
    layer_color = '#FFFFFF';
    layer_clahe = false;
    layer_slider = [100, 255];
    isMinimized = false;
    isShowed = true;
    apply_threshold = false;

    constructor(layer_id, layer_name, layer_color, layer_clahe, layer_slider, threshold) {
        this.layer_id = layer_id;
        this.layer_name = layer_name;
        this.layer_color = layer_color;
        this.layer_clahe = layer_clahe;
        this.layer_slider = layer_slider;
        this.apply_threshold = threshold;
        this.isShowed = true;
    }

}

class NdiLayer {
    layer_name;
    layer_color;
    layer_clahe;
    layer_slider;
    isMinimized = false;
    isShowed = true;
    apply_threshold;

    firstLayer;
    secondLayer;

    constructor(layer_name, firstLayer, secondLayer) {
        this.layer_name = layer_name;
        this.layer_color = '#FFFFFF';
        this.layer_clahe = false;
        this.layer_slider = [100, 255];
        this.apply_threshold = false;
        this.isShowed = true;
        this.firstLayer = firstLayer;
        this.secondLayer = secondLayer;
    }
}

class KmeansLayer {
    layer_id;
    layer_name;
    layer_color;
    layer_clahe;
    layer_slider;
    clusters;
    isMinimized = false;
    isShowed = true;
    apply_threshold;

    constructor(layer_id, layer_name, clusters) {
        this.layer_id = layer_id;
        this.layer_name = layer_name;
        this.layer_color = '#FFFFFF';
        this.layer_clahe = false;
        this.layer_slider = [100, 255];
        this.apply_threshold = false;
        this.isShowed = true;
        this.clusters = clusters
    }
}

class PcaLayer {
    layer_id;
    layer_name;
    layer_color;
    layer_clahe;
    layer_slider;
    isMinimized = false;
    isShowed = true;
    apply_threshold;

    constructor(layer_id, layer_name) {
        this.layer_id = layer_id;
        this.layer_name = layer_name;
        this.layer_color = '#FFFFFF';
        this.layer_clahe = false;
        this.layer_slider = [100, 255];
        this.apply_threshold = false;
        this.isShowed = true;
    }
}

class SoMLayer {
    layer_name;
    layer_color;
    layer_clahe;
    layer_slider;
    isMinimized = false;
    isShowed = true;
    specter_crop;
    apply_threshold;

    full_type_name = 'Similarity Objects Map Layer'

    constructor(layer_name, coordinates) {
        this.layer_name = layer_name;
        this.layer_color = '#FFFFFF';
        this.layer_clahe = false;
        this.layer_slider = [100, 255];
        this.apply_threshold = false;
        this.isShowed = true;
        this.data = coordinates;
    }
}

class IndexLayer {
    layer_name;
    layer_color;
    layer_clahe;
    layer_slider;
    isMinimized = false;
    isShowed = true;
    specter_crop;
    apply_threshold;

    layer_index
    parameters
    full_type_name = 'Index Layer'

    constructor(layer_name, layer_index, layer_color, parameters) {
        this.layer_name = layer_name;
        this.layer_color = layer_color;
        this.layer_clahe = false;
        this.layer_slider = [100, 255];
        this.apply_threshold = false;
        this.isShowed = true;
        this.layer_index = layer_index;
        this.parameters = parameters
    }
}
