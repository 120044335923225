<template>
    <v-card class="d-flex align-center flex-column" color="primary" flat>
        <reactive-map-drawer ref="map_drawer"></reactive-map-drawer>
        <v-card-actions class="w-100 justify-space-around">
            <v-btn color="error" @click="closeDialog">{{ $t('button/close') }}</v-btn>
            <v-btn :loading="objectDetectionProgress" disabled @click="launchObjectDetector">
                {{ $t('button/detectObjects') }}
            </v-btn>
            <v-btn color="green" @click="addNewLayer">
                {{ $t('newLayerDialog/addBtn') }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import ReactiveMapDrawer from "@/components/analyse-view/ReactiveMapDrawer";

export default {
    name: "SomLayer",
    components: {
        ReactiveMapDrawer
    },
    data() {
        return {
            objectDetectionProgress: false
        }
    },
    methods: {
        launchObjectDetector() {
            // this.objectDetectionProgress = true
            // // const layer = new KmeansLayer(-1, 'kmeans', 4)
            // this.$store.dispatch('computeLayer', {type: 'kmeans', layer: layer})
            //     .then(response => {
            //         this.$refs.map_drawer.initObjectDetector()
            //         this.objectDetectionProgress = false
            //     })
        },
        addNewLayer() {
            this.$refs.map_drawer.addLayer()
            this.closeDialog()
        },
        closeDialog() {
            this.$emit('closeDialog')
        },
    }
}
</script>

<style scoped>

</style>
