<template>
    <transition name="scroll-x-transition">
        <div v-if="isHideSettings" class="d-flex flex-column position-absolute increase-z-index">
            <div>
                <v-list color="transparent" dense nav rounded width="100%">
                    <v-list-group v-for="(graph, index) in getGraphsList"
                                  :key="graph.title"
                                  v-model="graph.active"
                                  class="rounded-xl bg-color-primary mb-1">
                        <template v-slot:activator>
                            <v-list-item-icon @click.stop="toggleGraphPoints(index, $event)">
                                <v-icon color="white" dense v-text="visibilityIcon(graph)"></v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title class="white--text" v-text="graph.title"></v-list-item-title>
                            </v-list-item-content>
                        </template>
                        <v-list-item v-for="point in graph.points" :key="point.id"
                                     class="justify-content-center bg-color-primary mb-1"
                                     dense>
                            {{ point.id }} (X:{{ point.x }}, Y:{{ point.y }})
                        </v-list-item>
                    </v-list-group>
                </v-list>
                <v-list color="transparent" dense nav rounded width="100%">
                    <v-list-item v-for="point in getPoints" :key="point.id"
                                 class="justify-content-center bg-color-primary mb-1"
                                 dense>
                        {{ point.id }} (X:{{ point.x }}, Y:{{ point.y }})
                    </v-list-item>
                    <v-list-item class="justify-content-center bg-color-accent current-point">
                        (X:{{ getCurrentPoint.x }}, Y:{{ getCurrentPoint.y }})
                    </v-list-item>
                </v-list>
            </div>
            <v-spacer></v-spacer>
            <div class="bottom-btn-group-row w-75">
                <v-btn color="#4a66d2" fab @click="addPoint">
                    <v-icon>mdi-plus</v-icon>
                </v-btn>
                <v-btn :disabled="isEmptyPointList" color="#4a66d2" fab @click="drawPlot">
                    <v-icon>mdi-map</v-icon>
                </v-btn>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: 'PointsConfigView',
    props: ["isHideSettings"],
    data() {
        return {}
    },
    computed: {
        getCurrentPoint() {
            return this.$store.getters.getCurrentPoint
        },
        getPoints() {
            return this.$store.getters.getPoints
        },
        getGraphsList() {
            return this.$store.getters.getGraphsList
        },
        isEmptyPointList() {
            return this.$store.getters.getPoints.length === 0
        }
    },
    methods: {
        toggleGraphPoints(index, event) {
            this.$store.commit('switchGraphVisibility', index)
        },
        visibilityIcon(graph) {
            if (graph.visible) {
                return 'mdi-eye'
            }
            return 'mdi-eye-off'
        },
        addPoint() {
            this.$store.commit('addCurrentPointToList')
        },
        drawPlot() {
            const specterId = this.$store.getters.getCurrentSpecter.id
            const points = this.$store.getters.getPoints
            const payload = {
                'specter_id': specterId,
                'points': points
            }
            this.$store.dispatch('drawPlot', payload)
        }
    }
}
</script>

<style scoped>
.increase-z-index {
    z-index: 1;
}

.bottom-btn-group-row {
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    min-width: 190px;
    padding: 10px 0;

    border-radius: 0 10px 0 0;
    z-index: 1;
}

.current-point {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
    transform: scale(1);
    animation: pulse 2s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 5px rgba(0, 0, 0, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
}

.bg-color-primary {
    background-color: #4a66d2;
}

.bg-color-accent {
    background-color: #1ab00a;
}
</style>
