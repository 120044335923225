import b64toBlob from "../../service/ConvertorsService";
import {
    pixelAnalyse,
    computeLayer,
    buildSimilarityMap,
    cannyObjectDetection,
    assembleSpecterFromVideos
} from "../../api/ProcessingAPI";
import {getComputedLayers} from "@/api/ProcessingAPI";
import addLayer from "@/service/LayerService";
import {i18n} from "@/plugins/translations";
import Vue from "vue";


export const vuexDataProcessingModule = {
    state: {
        clusterLayers: [],
        objectsDetectionResult: [],

        mapImage: null,

        drawningMode: false,
        atomicInteger: 1,
        graphs: [],
        points: [],
        currentPoint: {
            x: 0, y: 0,
            relativeX: 0, relativeY: 0,
            circleId: function () {
                return `circle ${this.x} ${this.y}`
            },
            textId: function () {
                return `text ${this.x} ${this.y}`
            }
        },
        relativePoint: {
            x: 100, y: 100,
            circleId: function () {
                return `circle ${this.x} ${this.y}`
            },
            textId: function () {
                return `text ${this.x} ${this.y}`
            }
        }
    },
    getters: {
        getClusterLayers: state => state.clusterLayers,
        getDetectionResult: state => state.objectsDetectionResult,

        getMapImage: state => state.mapImage,

        getVisibleGraphs: state => state.graphs.filter(item => item.visible),
        getCurrentPoint: state => state.currentPoint,
        getGraphsList: state => state.graphs,
        getGraphsCount: state => state.graphs.length,
        getPoints: state => state.points,
        isDrawningMode: state => state.drawningMode
    },
    mutations: {
        removeClusterLayer(state, layer) {
            const index = state.clusterLayers.indexOf(layer);
            state.clusterLayers.splice(index, 1);
        },
        clearClusterLayerList(state) {
            state.clusterLayers = []
        },
        clearObjectsList(state) {
            state.objectsDetectionResult = []
        },
        clearDrawPlot(state) {
            state.graphs = []
            state.points = []
            state.atomicInteger = 1
        },
        toggleDrawningMode(state) {
            state.drawningMode = !state.drawningMode
        },
        addNewGraph(state, graph) {
            const pointsCopy = Object.assign({}, state.points)
            state.relativePoint = {
                x: 100, y: 100,
                circleId: function () {
                    return `circle ${this.x} ${this.y}`
                },
                textId: function () {
                    return `text ${this.x} ${this.y}`
                }
            }
            state.points = []

            graph = URL.createObjectURL(b64toBlob(graph), {type: "image/png"});
            const graph_object = {
                image: graph,
                // usually pointsCopy belongs to "Observer" class.
                points: Object.values(JSON.parse(JSON.stringify(pointsCopy))),
                title: `Plot #${state.graphs.length + 1}`,
                active: false,
                visible: false
            }

            state.graphs.push(graph_object)
        },
        deleteGraph(state, graph) {
            state.graphs = state.graphs.filter(item => item != graph)
        },
        setCurrentPointCoordinate(state, point) {
            state.currentPoint.x = point.x
            state.currentPoint.y = point.y
        },
        switchGraphVisibility(state, index) {
            const graph = state.graphs[index]
            graph.visible = !graph.visible
            Vue.set(state.graphs, index, graph)
        },
        addCurrentPointToList(state) {
            const currentPointClone = Object.assign({}, state.currentPoint)
            currentPointClone.id = state.atomicInteger

            currentPointClone.circleId = currentPointClone.circleId()
            currentPointClone.textId = currentPointClone.textId()

            state.points.push(currentPointClone)

            ++state.atomicInteger
        },
        setMapImage(state, image) {
            if (state.mapImage != null) {
                URL.revokeObjectURL(state.image);
            }
            state.mapImage = URL.createObjectURL(b64toBlob(image), {type: "image/webp"})
        },
        removeMapImage(state, image) {
            if (state.mapImage != null) {
                URL.revokeObjectURL(state.image);
                state.mapImage = null
            }
        },
    },
    actions: {
        getComputedLayers({commit, rootState}) {
            return new Promise(((resolve, reject) => {
                getComputedLayers(rootState.dataProvider.currentSpecter.id)
                    .then(response => {
                        resolve(response.data['layers'])
                    })
            }))
        },
        computeLayer({commit, rootState}, payload) {
            const beforeReqSpecterID = rootState.dataProvider.currentSpecter.id
            const name = rootState.dataProvider.currentSpecter.name

            return new Promise(((resolve, reject) => {
                computeLayer(rootState.dataProvider.currentSpecter.id, payload.type, payload)
                    .then(response => {
                        if (response.status === 202) {
                            this._vm.$toast.info(i18n.t('notification/taskInQueue'))
                            response.data.task.task_name = name
                            response.data.task.specter_id = beforeReqSpecterID
                            commit('addNewTaskInQueue', response.data.task)
                        } else {
                            if (this.state.dataProvider.currentSpecter.id === beforeReqSpecterID) {
                                commit('addLayer', payload.layer)
                            }
                        }
                    })
                    .catch(error => {
                        reject(error);
                    })
            }))
        },
        buildSimilarityMap({commit, dispatch, rootState}, payload) {
            return new Promise(((resolve, reject) => {
                buildSimilarityMap(rootState.dataProvider.currentSpecter.id, payload)
                    .then(response => {
                        commit('setMapImage', response.data.img)
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            }))
        },
        getObjectsDetectedByCanny({commit, dispatch, rootState}) {
            return new Promise(((resolve, reject) => {
                cannyObjectDetection(rootState.dataProvider.currentSpecter.id)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            }))
        },
        drawPlot({commit, dispatch, rootState}, payload) {
            return new Promise((resolve, reject) => {
                pixelAnalyse(rootState.dataProvider.currentSpecter.id, payload)
                    .then(response => {
                        commit('addNewGraph', response.data.data)
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        },
        assembleSpecterFromVideos({commit}, formData) {
            return new Promise((resolve, reject) => {
                assembleSpecterFromVideos(formData)
                    .then(response => {
                        resolve(response)
                    })
                    .catch(error => {
                        reject(error)
                    })
            })
        }
    },

};
