import {
    getAllUsers,
    deleteUser,
    updateUser,
    addUser,
    updateSpecter,
    deleteSeveralSpecters,
    deleteSpecter
} from "@/api/AdminAPI";

export const vuexAdminModule = {
    state: {
        usersList: []
    },
    getters: {
        getUsersList: state => state.usersList
    },
    mutations: {
        setUsersList(state, users) {
            state.usersList = users
        }
    },
    actions: {
        fetchUsersList({commit, dispatch}) {
            return new Promise(((resolve, reject) => {
                getAllUsers()
                    .then(resp => {
                        commit('setUsersList', JSON.parse(resp.data.data))
                        resolve(resp)
                    })
                    .catch(err => {
                        reject(err)
                    })
            }))
        },
        addNewUser({commit}, user) {
            return new Promise(((resolve, reject) => {
                addUser(user)
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        reject(err)
                    })
            }))
        },
        changeUser({commit}, user) {
            return new Promise(((resolve, reject) => {
                updateUser(user)
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        reject(err)
                    })
            }))
        },
        deleteUser({commit}, user_id) {
            return new Promise(((resolve, reject) => {
                deleteUser(user_id)
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        reject(err)
                    })
            }))
        },
        updateSpecter({commit}, payload) {
            return new Promise(((resolve, reject) => {
                updateSpecter(payload.id, payload.data)
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        reject(err)
                    })
            }))
        },
        deleteSeveralSpecters({commit}, specterIdList) {
            return new Promise(((resolve, reject) => {
                deleteSeveralSpecters(specterIdList)
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        reject(err)
                    })
            }))
        },
        deleteSpecter({commit}, specterId) {
            return new Promise(((resolve, reject) => {
                deleteSpecter(specterId)
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        reject(err)
                    })
            }))
        }
    },
};
