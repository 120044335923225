<template>
    <v-list-group append-icon="" class="card bg-color-primary" @click="resetTimer">
        <template v-slot:activator>
            <v-list-item v-show="confirmDeletionDialog" class="p-0 confirm-dialog" flat>
                <v-btn color="red"
                       @click.stop="deleteLayer($event)">{{ $t('button/delete') }}
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn color="green" @click.stop="confirmDeletion($event)">{{ $t('button/close') }}</v-btn>
            </v-list-item>
            <v-list-item v-show="! confirmDeletionDialog" class="p-0" flat>
                <v-list-item-icon class="mr-4 align-items-center">
                    <v-btn text x-small @click.stop="switchLayerVisibility($event)">
                        <v-icon :color="layer.layer_color" small>{{ visibilityIcon(layer.isShowed) }}
                        </v-icon>
                    </v-btn>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title class="white--text text-center">{{ computedLayerName(layer) }}
                    </v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon class="align-items-center">
                    <v-btn text x-small @click.stop="confirmDeletion($event)">
                        <v-icon color="red lighten-3" small>mdi-delete</v-icon>
                    </v-btn>
                </v-list-item-icon>
            </v-list-item>
        </template>
        <v-list-item flat>
            <v-list-item-content class="card__body d-flex flex-row">
                <div v-if="isPlainLayer" class="card__body-id-input d-flex flex-row">
                    <div class="d-flex flex-column mr-2">
                        <a class="small-icon" @click="incrementLayerNumber">
                            <v-icon small>mdi-chevron-up</v-icon>
                        </a>
                        <a class="small-icon" @click="decrementLayerNumber">
                            <v-icon small>mdi-chevron-down</v-icon>
                        </a>
                    </div>
                    <v-text-field
                        v-model="layer.layer_id"
                        :hint="'(1 - ' + getUpBorder + ')'"
                        :label="$t('analyse/layersId')"
                        :rules="[rules.check_border]" class="no-select"
                        clearable
                        color="white"
                        dense>
                    </v-text-field>
                </div>
                <div class="card__body-color-picker d-flex flex-row justify-content-between">
                    <span class="ml-5 align-self-center">{{ $t('analyse/layerColor') }} </span>
                    <v-menu :close-on-click="true" :close-on-content-click="false" offset-x offset-y>
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" text>
                                <v-icon>mdi-palette</v-icon>
                            </v-btn>
                        </template>
                        <v-list-item>
                            <v-color-picker v-model="layer.layer_color" class="" hide-inputs hide-mode-switch
                                            mode='hexa'></v-color-picker>
                        </v-list-item>
                    </v-menu>
                </div>
                <div class="card__body-clahe d-flex flex-row justify-content-between mb-2">
                    <span class="ml-5 align-self-center">{{ $t('analyse/layerClahe') }} </span>
                    <div style="margin-right: 4px"> <!-- This if because margin on v-switch does not work -->
                        <v-switch v-model="layer.layer_clahe" class="apply-clahe" color="white" dense
                                  hide-details></v-switch>
                    </div>
                </div>
                <div class="card__body-thrashold d-flex flex-row justify-content-between mb-2">
                    <span class="ml-5 align-self-center">{{ $t('analyse/layerThreshold') }}</span>
                    <div style="margin-right: 4px">
                        <v-switch v-model="layer.apply_threshold" class="apply-threshold" color="white" dense
                                  hide-details></v-switch>
                    </div>
                </div>
                <transition name="fade">
                    <div v-show="layer.apply_threshold" class="d-flex flex-column">
                        <v-range-slider v-model="layer.layer_slider" :thumb-size="0" class="slider"
                                        color="white"
                                        hide-details
                                        max="255"
                                        min="0"
                                        thumb-color="white"
                                        thumb-label="always"
                                        track-color="gray"
                        >
                        </v-range-slider>
                        <div class="d-flex justify-space-around">
                            <v-text-field
                                v-model="layer.layer_slider[0]"
                                :rules="[rules.slider_border]"
                                class="mt-0 pt-0"
                                style="width: 40px"
                                type="number"
                            ></v-text-field>
                            <v-spacer></v-spacer>
                            <v-text-field
                                v-model="layer.layer_slider[1]"
                                :rules="[rules.slider_border]"

                                class="mt-0 pt-0"
                                style="width: 40px"
                                type="number"
                            ></v-text-field>
                        </div>
                    </div>
                </transition>
            </v-list-item-content>
        </v-list-item>
    </v-list-group>
</template>

<script>
export default {
    name: 'CardView',
    props: ['layer'],
    data() {
        return {
            timerInterval: 2,
            confirmDeletionDialog: false,
            rules: {
                check_border: value => {
                    if (value > 0 && value <= this.getUpBorder) {
                        this.error_in_settings = false;
                        return true;
                    }
                    this.error_in_settings = true;
                    return this.$i18n.messages[this.$i18n.locale]['layerNumberError']
                },
                not_neg: value => (value > 0) || this.$i18n.messages[this.$i18n.locale]['layerNumberError'],
                slider_border: value => (value >= 0 && value <= 255)
            },
        }
    },
    computed: {
        getSpecter() {
            return this.$store.getters.getCurrentSpecter;
        },
        isPlainLayer() {
            return this.layer.type === 'plain'
        },
        getUpBorder() {
            return this.$store.getters.getCurrentSpecter.layers.length
        }
    },
    methods: {
        computedLayerName(layer) {
            if (layer.type === 'plain') {
                const layerNumber = Number(layer.layer_id);
                let cur_layer = this.getSpecter["layers"][layerNumber - 1];
                if (cur_layer !== undefined) return cur_layer["name"];
            } else {
                return layer.layer_name
            }
        },
        resetTimer() {
            this.$emit('reset-timer')
        },
        incrementLayerNumber() {
            this.layer.layer_id = Number(this.layer.layer_id) + 1;
            if (this.layer.layer_id > this.getUpBorder)
                this.layer.layer_id = 1;
        },
        decrementLayerNumber() {
            this.layer.layer_id = Number(this.layer.layer_id) - 1;
            if (this.layer.layer_id === 0)
                this.layer.layer_id = this.getUpBorder;
        },
        switchLayerVisibility() {
            this.layer.isShowed = !this.layer.isShowed
            this.icon = this.layer.isShowed ? 'mdi-eye' : 'mdi-eye-off'
        },
        confirmDeletion() {
            this.confirmDeletionDialog = !this.confirmDeletionDialog
        },
        deleteLayer() {
            this.$emit('delete-layer', this.layer)
            this.confirmDeletionDialog = !this.confirmDeletionDialog
        },
        visibilityIcon(visibilityState) {
            return visibilityState ? 'mdi-eye' : 'mdi-eye-off'
        }
    }
}
</script>

<style lang="scss" scoped>

.confirm-dialog {
    display: flex;
    z-index: 2;
}

.bg-color-primary {
    background-color: #4a66d2;
}

.wide-button {
    display: flex;
    justify-content: center;
    width: 70px
}

.body-item-height {
    height: 36px;
}
</style>1
