<template>
    <div class="ChooseSpecter">
        <nav class="navbar navbar-expand-lg navbar-light">
            <span class="mr-auto title-text">{{ $t('specter/title') }}</span>
            <v-btn class="mr-1 ml-1" text v-on:click="showSearchBox = !showSearchBox">
                <v-icon>mdi-magnify</v-icon>
            </v-btn>
            <notification-view></notification-view>
            <change-language-view></change-language-view>
            <v-btn icon v-on:click="rightSidebar = !rightSidebar">
                <v-icon>mdi-chevron-double-left</v-icon>
            </v-btn>
        </nav>
        <v-navigation-drawer v-model="leftSidebar" class="sidebar" color="secondary" fixed floating temporary>
            <v-list class="sidebar-list" dense nav>
                <v-list-item v-show="specterDescription != null">
                    <span class="sidebar-description">{{ $t('specterDesc/description') }}: {{
                            specterDescription
                        }}</span>
                </v-list-item>
                <v-list-item>
                    <span>{{ $t('specterDesc/author') }}: {{ specterAuthor }}</span>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item>
                    {{ $t('specterDesc/height') }}: {{ specterHeight }}
                </v-list-item>
                <v-list-item>
                    {{ $t('specterDesc/width') }}: {{ specterWidth }}
                </v-list-item>
                <v-list-item>
                    {{ $t('specterDesc/layers') }}: {{ specterLayers.length }}
                </v-list-item>
                <v-divider></v-divider>
                <v-list dense two-line>
                    <v-list-item
                        v-for="item in specterLayers"
                        v-bind:key="item.name">
                        <v-list-item-content class="pt-0 pb-0">
                            <span>{{ $t('specterDesc/name') }}: {{ item.name }}</span>
                            <span>{{ $t('specterDesc/waveLength') }}: {{ item.wavelength | floatFormat }}</span>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-list>
        </v-navigation-drawer>
        <v-navigation-drawer :mini-variant="rightSidebar" class="sidebar" color="secondary" fixed floating permanent
                             right>
            <v-list class="sidebar-list d-flex flex-column text-center" dense height="100%" nav>
                <div v-show="!rightSidebar" class="text-left">
                    <v-list-item class="d-flex" dense>
                        <v-list-item-content class="justify-center">
                            {{ usernameFromLocalStorage }} : {{ roleFromLocalStorage }}
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item dense>
                        <v-list-item-content class="d-flex justify-center flex-column">
                            <div>
                                <span>ODM : <span v-if="ODMStatus">Authorized</span><span
                                    v-else>Unauthorized</span> </span>
                            </div>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider></v-divider>
                </div>
                <v-list-item v-if="getServerLaunchParameter" class="remove-text-decoration" to="/flight">
                    <v-list-item-icon>
                        <v-icon>mdi-airplane</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Flights</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="getServerLaunchParameter" class="remove-text-decoration"
                             @click.stop="serverSettingsDialog = true">
                    <v-list-item-icon>
                        <v-icon>mdi-cogs</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Server Settings</v-list-item-title>
                        <system-settings
                            v-if="getServerLaunchParameter"
                            :dialog="serverSettingsDialog"
                            @close="serverSettingsDialog = false"></system-settings>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="getServerLaunchParameter" class="remove-text-decoration"
                             @click.stop="checkConnectionToServer">
                    <v-list-item-icon>
                        <v-icon>mdi-server-network</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Remote Auth</v-list-item-title>
                        <authorization-dialog
                            :dialog="remoteAuthDialog"
                            title="Авторизация на удаленном сервере"
                            @close="remoteAuthDialog = false"
                            @login="remoteServerLogin">
                        </authorization-dialog>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item @click="fetchNewData">
                    <v-list-item-icon>
                        <v-icon>mdi-autorenew</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{ $t('specter/refresh') }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item @click.stop="showDataUploaderDialog = true">
                    <v-list-item-icon>
                        <v-icon>mdi-upload</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{ $t('specter/upload') }}</v-list-item-title>
                        <uploader-view :dialog="showDataUploaderDialog"
                                       @close="showDataUploaderDialog = false"></uploader-view>
                    </v-list-item-content>
                </v-list-item>
                <v-spacer></v-spacer>
                <v-list-item v-if="roleFromLocalStorage === 'admin'" to="/admin">
                    <v-list-item-icon>
                        <v-icon>mdi-skull-crossbones-outline</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Administration</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item @click="logout">
                    <v-list-item-icon>
                        <v-icon>mdi-exit-to-app</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{ $t('specter/logout') }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>

        <div class="navigation-bar__placeholder"></div>

        <search-box-view v-show="showSearchBox"></search-box-view>

        <div class="body">
            <v-container class="card-container justify-content-center" fluid>
                <specter-card v-for="(specter, index) in getSpectersList()" :key="specter.id"
                              :index="index"
                              :isRemoteAuthentificated="isRemoteAuthentificated" :specter="specter" @choose="choose"
                              @showChangeDialog="showChangeDialog"
                              @showDeleteDialog="showDeleteDialog"
                              @showInformationDialog="showInformationDialog"
                />
            </v-container>

            <v-container v-if="getSpectersCount > countOfVisibleElements" fluid>
                <v-flex class="d-flex justify-content-space-around">
                    <v-spacer></v-spacer>
                    <v-btn @click="showMore">{{ $t('specter/showMore') }}</v-btn>
                    <v-spacer></v-spacer>
                </v-flex>
            </v-container>


            <v-spacer></v-spacer>
            <v-overlay :value="isLoading" absolute opacity="0.9" z-index="5">
                <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>
        </div>

        <v-dialog v-if="deleteDialog" v-model="deleteDialog" :retain-focus="false" max-width="450px" persistent>
            <v-card color="secondary">
                <v-card-title class="headline">
                    <span class="text-center text-break">
                    {{ $t('specterDelete/title') }}
                    </span>
                </v-card-title>
                <v-card-text>
                    <v-card class="card" outlined>
                        <v-img class="image-display" v-bind:src="getSpecter().preview">
                            <v-card-title class="d-flex justify-space-between">
                                <span class="animated-text">{{ getSpecter().name }}</span>
                            </v-card-title>
                        </v-img>
                    </v-card>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="darken-1" v-on:click="deleteDialog = false">{{ $t('button/close') }}</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="red darken-1" v-on:click="deleteSpecter()">{{ $t('button/delete') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <specter-setting-view :showDialog="changeDialog"></specter-setting-view>
    </div>
</template>

<script>
import SpecterCard from "@/components/specter-view/SpecterCard";
import SystemSettings from "@/components/support-view/SystemSettings";
import AuthorizationDialog from "@/components/support-view/AuthorizationDialog";
import {checkRemoteAuth, remoteAuth} from "@/api/UtilsAPI";
import SearchBoxView from "@/components/support-view/SearchBoxView";
import SpecterSettingView from "@/components/support-view/SpecterSettingView";
import ChangeLanguageView from "@/components/support-view/ChangeLanguageView";
import UploaderView from "@/components/support-view/UploaderView";
import LayerFactory from "@/model/Layer";
import NotificationView from "@/components/support-view/NotificationView";


export default {
    name: "HyperspecterView",
    components: {
        NotificationView,
        SystemSettings,
        SpecterCard,
        SearchBoxView,
        SpecterSettingView,
        ChangeLanguageView,
        UploaderView,
        AuthorizationDialog
    },
    data() {
        return {
            showDataUploaderDialog: false,
            showSearchBox: false,
            isLoading: true,
            leftSidebar: false,
            rightSidebar: true,
            deleteDialog: false,
            snackName: '',
            changeDialog: false,
            serverSettingsDialog: false,

            countOfVisibleElements: 10,

            remoteAuthDialog: false,
            isRemoteAuthentificated: false,
        }
    },
    filters: {
        floatFormat(value) {
            return value.toFixed(2)
            // return value
        }
    },
    computed: {
        getSpectersCount() {
            return this.$store.getters.getSpecters.length
        },
        getSpecterFilter() {
            return this.$store.getters.getSpecterFilter
        },
        usernameFromLocalStorage: function () {
            if (localStorage.getItem('username') === null) {
                return 'Guest'
            }
            return localStorage.getItem('username')
        },
        roleFromLocalStorage() {
            return localStorage.getItem('role')
        },
        ODMStatus() {
            return this.$store.getters.isODMAuthorized
        },
        specterLayers: function () {
            const specter = this.getSpecter();
            if (specter !== null && specter.type !== 'orthophoto')
                return specter.layers;
            return [];
        },
        specterDescription: function () {
            const specter = this.getSpecter();
            if (specter !== null)
                return specter.description;
            return null;
        },
        specterHeight: function () {
            const specter = this.getSpecter();
            if (specter !== null)
                return specter.height;
            return null;
        },
        specterWidth: function () {
            const specter = this.getSpecter();
            if (specter !== null)
                return specter.width;
            return null;
        },
        specterAuthor: function () {
            const specter = this.getSpecter();
            if (specter !== null)
                return specter.author;
            return null;
        },
        getServerLaunchParameter() {
            return Number(process.env.VUE_APP_PI_VERSION)
        },
    },
    mounted() {
        this.fetchNewData()
        this.$store.dispatch('checkODMAuth')
        this.isLoading = false
    },
    watch: {
        'getSpecterNameFilter'() {
            this.countOfVisibleElements = 10
        }
    },
    methods: {
        getSpectersList() {
            let specterList = this.$store.getters.getSpecters
            const filteringParam = this.$store.getters.getSpecterFilter

            if (filteringParam.isOnlyMine) {
                specterList = specterList.filter(item => item.author === this.usernameFromLocalStorage)
            }
            if (filteringParam.regexp) {
                specterList = specterList.filter(item => item ? item.name.includes(filteringParam.regexp) : false)
            }

            return specterList.slice(0, this.countOfVisibleElements)
        },
        showMore() {
            this.countOfVisibleElements += 10
        },
        logout: function () {
            this.$store.dispatch('authLogout')
                .then(() => {
                    this.$router.push({name: 'AuthorizationView'})
                })
        },
        getSpecter: function () {
            return this.$store.getters.getCurrentSpecter
        },
        showChangeDialog: function (item) {
            this.changeDialog = !this.changeDialog;
            this.$store.commit('setChoosenSpecterById', item.id);
        },
        showDeleteDialog: function (item) {
            this.deleteDialog = true;
            this.$store.commit('setChoosenSpecterById', item.id);
        },
        showInformationDialog: function (item) {
            this.leftSidebar = true;
            this.$store.commit('setChoosenSpecterById', item.id);
        },
        fetchNewData: function () {
            this.isLoading = true;
            this.countOfVisibleElements = 10;
            this.$store.dispatch('fetchNewData')
                .then(() => {
                    this.isLoading = false;
                })
                .catch(error => {
                    console.log(error)
                    if (error.response.status !== 401) {
                        this.$router.push({name: 'AuthorizationView'});
                    }
                })
        },
        choose: function (specterID) {
            this.$store.commit('setChoosenSpecterById', specterID);

            const specter = this.$store.getters.getCurrentSpecter;

            this.$store.commit('clearLayersList');
            this.$store.commit('clearImage');

            const r = new LayerFactory().createLayer('plain', {}, specter['r'], specter['layers'][specter['r'] - 1].name,
                '#FF0000FF', false, [160, 190], false);
            const g = new LayerFactory().createLayer('plain', {}, specter['g'], specter['layers'][specter['g'] - 1].name,
                '#00FF00FF', false, [160, 190], false);
            const b = new LayerFactory().createLayer('plain', {}, specter['b'], specter['layers'][specter['b'] - 1].name,
                '#0000FFFF', false, [160, 190], false);

            this.$store.commit('addLayer', r);
            this.$store.commit('addLayer', g);
            this.$store.commit('addLayer', b);

            this.$router.push({name: 'AnalyseView'})
        },
        deleteSpecter: function () {
            const specterForDelete = this.$store.getters.getCurrentSpecter;
            this.$store.dispatch('deleteHyperspecter', specterForDelete);
            this.deleteDialog = false;
        },
        remoteServerLogin(payload) {
            remoteAuth(payload)
                .then(res => {
                    this.$toast.info(this.$t('notification/successLogin'))
                    this.isRemoteAuthentificated = true
                })
                .catch(err => {
                    this.$toast.error(this.$t('notification/erroneousLogin'))
                    this.isRemoteAuthentificated = false
                })
        },
        checkConnectionToServer() {
            checkRemoteAuth()
                .then(res => {
                    this.remoteAuthDialog = false
                    this.isRemoteAuthentificated = true
                })
                .catch(err => {
                    this.remoteAuthDialog = true
                })
        },
    }
}
</script>

<style scoped>
.ChooseSpecter {
    display: flex;
    flex-direction: column;
}

.navigation-bar__placeholder {
    height: 52px;
}

.sidebar {
    margin-top: 52px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
}

.sidebar-list {
    padding-bottom: 60px;
}

.navbar {
    display: flex;

    background: #4a66d2;
    position: fixed;
    z-index: 10;
    width: 100%;

    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
}

.title-text {
    font-weight: bold;
    font-size: x-large;
    color: white;
    margin-left: 30px;
}

.v-list-item {
    flex: 0 0
}

.body {
    margin: 0 !important;
    padding-right: 56px;
}

.card-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 30px;
    max-width: 100% !important;
}


.sidebar-description {
    overflow-wrap: anywhere;
}

</style>
