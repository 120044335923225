<template>
    <v-container class="search-box">
        <v-row>
            <v-col></v-col>
            <v-col cols="6">
                <v-text-field v-model="specterFilter" :hide-details="true"
                              :label="$t('searchBox/search')" class="search-input" color="white" dark dense
                              outlined></v-text-field>
            </v-col>
            <v-col></v-col>
        </v-row>
        <v-row>
            <v-col></v-col>
            <v-col class="d-flex flex-column" cols="6">
                <span>{{ $t('searchBox/filters') }}: </span>
                <span class="d-flex flex-row">
                    {{ $t('searchBox/onlyMineFilter') }}:
                    <v-checkbox v-model="isOnlyMine" class="pl-2" color="blue"></v-checkbox>
                </span>
            </v-col>
            <v-col></v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "SearchBoxView",
    data() {
        return {
            isOnlyMine: false,
            specterFilter: ''
        }
    },
    watch: {
        'isOnlyMine'() {
            this.setSpecterFilter()
        },
        'specterFilter'() {
            this.setSpecterFilter()
        }
    },
    methods: {
        setSpecterFilter() {
            this.$store.commit('setSpecterFilter', {'isOnlyMine': this.isOnlyMine, 'regexp': this.specterFilter})
        }
    }
}
</script>

<style scoped>
.search-box {
    background-color: #313f8a;
    border-radius: 0 0 20px 20px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
}
</style>
