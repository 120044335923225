import VueSocketIO from 'vue-socket.io'
import io from 'socket.io-client'
import {baseURL} from "@/plugins/axios";
import {store} from "@/plugins/vuex";
import Vue from "vue";

const socketio = new VueSocketIO({
    debug: false,
    connection: io(baseURL, {
        upgrade: false,
        transports: ['websocket']
    }),
    vuex: {
        store,
        actionPrefix: 'SOCKET_',
        mutationPrefix: 'SOCKET_'
    },
})

Vue.use(socketio)
