<template>
    <v-overlay :value="isClusteringOverlayShowed" v-bind:class="{'secondStage': currentStage === 1}">
        <v-card max-height="100%" max-width="100%">
            <v-list v-if="currentStage === 0" color="primary" max-width="500px">
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>{{ $t('indiDialog/title') }}</v-list-item-title>
                        <v-btn @click="chooseCalculated">{{ $t('indiDialog/existing') }}</v-btn>
                        <v-btn @click="calculateNewStage">{{ $t('indiDialog/calculateNew') }}</v-btn>
                        <v-btn @click="isClusteringOverlayShowed = false">{{ $t('button/back') }}</v-btn>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            <indi-object-selection
                v-if="currentStage === 1"
                @back="backOnPreviousStage"
                @calculateINDI="calculateINDI"></indi-object-selection>

            <v-card v-if="currentStage === 2" class="p-2" color="secondary">
                <v-select v-model="choosenLayer" :hide-details="true" :items="computedLayers"
                          :loading="checkLoadingStater"
                          background-color="primary"
                          color="white" item-color="#6c757d" item-text="name" return-object solo></v-select>
                <v-img v-bind:src="setPreview(choosenLayer)"/>


                <v-card-actions>
                    <v-btn color="danger" @click="backOnPreviousStage">{{ $t('button/back') }}</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="success" @click="addINDI(choosenLayer)">{{ $t('indiDialog/btnProcess') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-card>
    </v-overlay>
</template>

<script>
import b64toBlob from "@/service/ConvertorsService";
import IndiObjectSelection from "@/components/analyse-view/IndiObjectSelection";
import LayerFactory from "@/model/Layer";

export default {
    name: "IndiView",
    components: {IndiObjectSelection},
    props: {
        showDialog: Boolean,
    },
    watch: {
        'showDialog': function () {
            this.isClusteringOverlayShowed = true;
        }
    },
    data() {
        return {
            // 0 - show calculated INDI or calculate new
            // 1 - choose an object detection method
            // 2 - choose objects
            // 3 - manual object selection
            // 4 - calculated
            currentStage: 0,
            currentMethod: 'KMeans',
            percentOfObject: 0,
            clusters: 4,
            iterations: 50,
            isClusteringOverlayShowed: false,

            computedLayers: [],
            choosenLayer: null,
        }
    },
    computed: {
        getLayers() {
            return this.$store.getters.getLayers;
        },
        checkLoadingStater() {
            return this.computedLayers.length === 0
        }
    },
    methods: {
        calculateINDI(objects_1, objects_2) {
            this.closeOverlay()
            const payload = {
                type: 'informative',
                objects: {'class_1': objects_1, 'class_2': objects_2}
            }
            this.$store.dispatch('computeLayer', payload)
        },
        calculateNewStage() {
            this.currentStage = 1
        },
        backOnPreviousStage() {
            this.currentStage = 0
        },
        closeOverlay() {
            this.isClusteringOverlayShowed = false
            this.currentStage = 0
        },
        chooseCalculated() {
            this.currentStage = 2
            this.$store.dispatch('getComputedLayers').then(response => {
                if (!response || response.length === 0) {
                    this.currentStage = 0
                } else {
                    this.computedLayers = response
                }
            })
        },
        setPreview(layer) {
            if (layer !== null)
                return URL.createObjectURL(b64toBlob(layer.img), {type: "image/webp"});
        },
        addINDI(layer) {
            const layer_name = layer.name
            const first_layer = layer.l1
            const second_layer = layer.l2
            const layer1 = new LayerFactory().createLayer('ndi', {layer_name, first_layer, second_layer})
            const layer2 = new LayerFactory().createLayer('ndi', {layer_name, first_layer, second_layer})

            layer1.layer_slider = layer.thresholds['1']
            layer1.apply_threshold = true
            layer1.layer_color = '#FF0000'
            layer2.layer_slider = layer.thresholds['2']
            layer2.apply_threshold = true
            layer2.layer_color = '#00FF00'
            this.$store.commit('addLayer', layer1)
            this.$store.commit('addLayer', layer2)

            this.closeOverlay()
        }
    }
}
</script>

<style>
.fix-maxsize {
    max-height: 70%;
    max-width: 70%;
}
</style>
