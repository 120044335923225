<template>
    <div>
        <v-app-bar>
            <v-app-bar-nav-icon to="/specter"></v-app-bar-nav-icon>
            <v-app-bar-title>Administration</v-app-bar-title>
        </v-app-bar>

        <v-container fluid>
            <v-row>
                <v-col cols="4">
                    <v-card light>
                        <v-card-title>Open dashboard</v-card-title>
                        <v-card-actions class="d-flex flex-column justify-content-center">
                            <v-btn :href="getDashboardAddress" class="mb-3" outlined target="_blank">Open Flower</v-btn>
                            <v-btn href="https://odm.copter.space/dashboard/" outlined target="_blank">Open ODM</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
                <v-col cols="8">
                    <memory-management></memory-management>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <user-management></user-management>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <specter-management></specter-management>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import {baseURL} from "@/plugins/axios";
import MemoryManagement from "@/components/admin-view/MemoryManagement";
import SpecterManagement from "@/components/admin-view/SpecterManagement";
import UserManagement from "@/components/admin-view/UserManagement";

export default {
    name: "AdminPanel",
    components: {UserManagement, SpecterManagement, MemoryManagement},
    computed: {
        getDashboardAddress() {
            if (process.env.NODE_ENV === 'development') {
                return 'http://localhost:5556/monitor/dashboard'
            } else {
                return baseURL + '/monitor/dashboard'
            }
        },
    },

}
</script>

<style scoped>
</style>
