/* eslint-disable no-unused-vars */
import addLayer from "@/service/LayerService";
import {getSpectruAndWifiSettings, setSpectruAndWifiSettings} from "@/api/UtilsAPI";
import {createToastInterface} from "vue-toastification";
import {getMemoryStatus} from "@/api/CameraAPI";

const toast = createToastInterface({position: "top-right",})


export const vuexUtilsModule = {
    state: {
        atomicTaskId: 1,
        snack: '1',
        memoryStatus: '',
        usagePercent: 10,

        specterFilter: {'isOnlyMine': false, 'regexp': ''},

        taskQueueList: [],
        taskCompletedAndFailedList: [],
    },
    getters: {
        getSpecterFilter: state => state.specterFilter,
        getUnreadMessagesCount: state => state.messages,
        getAllTasksList: state => state.taskQueueList.concat(state.taskCompletedAndFailedList),
        getFinishedTaskList: state => state.taskCompletedAndFailedList,
        getMemoryStatus: state => state.memoryStatus,
        getUsagePercent: state => state.usagePercent,
    },
    mutations: {
        setMemoryStatus(state, status) {
            state.memoryStatus = status
        },
        setUsagePercent(state, percent) {
            state.usagePercent = percent
        },
        setSnack(state, snack) {
            state.snack = snack;
        },
        setSpecterFilter(state, specterFilter) {
            state.specterFilter = specterFilter;
        },
        addNewTaskInQueue(state, task) {
            state.taskQueueList.push(task)
        },
        updateTasksInQueue(state, updatedTaskList) {
            const taskQueuedList = []
            state.taskCompletedAndFailedList = []
            for (const [key, task] of updatedTaskList.entries()) {
                switch (task.task_status) {
                    case 'FAILURE':
                        state.taskCompletedAndFailedList.push(task)
                        break
                    case 'SUCCESS':
                        state.taskCompletedAndFailedList.push(task)
                        break
                    default:
                        taskQueuedList.push(task)
                        break
                }
            }
            state.taskQueueList = taskQueuedList
        },
        clearMessages(state) {
            state.taskCompletedAndFailedList = []
        },
    },
    actions: {
        SOCKET_task_status({commit}, data) {
            commit('updateTasksInQueue', data)
        },
        getSpectruAndWifiSettings() {
            return new Promise((resolve, reject) => {
                getSpectruAndWifiSettings()
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        setSpectruAndWifiSettings({state, commit, dispatch}, payload) {
            return new Promise((resolve, reject) => {
                setSpectruAndWifiSettings(payload)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        fetchMemoryStatus({commit, dispatch}) {
            return new Promise(((resolve, reject) => {
                getMemoryStatus()
                    .then(resp => {
                        const total = resp.data['total'];
                        const free = resp.data['free'];
                        const used = resp.data['used'];
                        const percent = resp.data['percent'];
                        commit('setMemoryStatus', `${used}Gb/${total}Gb (${percent}%)`)
                        commit('setUsagePercent', percent)
                        resolve(resp)
                    })
                    .catch(err => {
                        reject(err)
                    })
            }))
        },
    }
}
