import Vuetify from 'vuetify/lib';
import Vue from 'vue'

export const vuetify = new Vuetify({
    theme: {
        dark: true,
        themes: {
            dark: {
                primary: '#4a66d2',
                secondary: '#313f8a',
            }
        }
    },
});

Vue.use(Vuetify, {
    iconfont: 'mdi'
});


