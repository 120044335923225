<template>
    <v-card class="d-flex align-center flex-column" color="primary" flat>
        <v-card-actions class="w-100 justify-space-around">
            <v-btn color="error" @click="closeDialog">{{ $t('button/close') }}</v-btn>
            <v-btn :disabled="!valid" color="green" @click="addNewLayer()">
                {{ $t('newLayerDialog/addBtn') }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import LayerFactory from "@/model/Layer";

export default {
    name: "PcaLayer",
    data() {
        return {
            valid: true,
        }
    },
    methods: {
        closeDialog() {
            this.$emit('closeDialog')
        },
        addNewLayer() {
            const layer_name = 'PCA'
            const layer_id = -1
            const layer = new LayerFactory().createLayer('pca', {layer_id, layer_name})
            this.$store.dispatch('computeLayer', {type: 'pca', layer: layer})

            this.closeDialog()
        }
    }
}
</script>

<style scoped>

</style>
