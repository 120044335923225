<template>
    <v-data-table
        :headers="userHeaders"
        :items="usersList"
        :loading="isSpecterTableLoading"
        :search="search"
        dense
        light
        loading-text="Loading... Please wait"
    >
        <template v-slot:top>
            <v-toolbar flat>
                <v-toolbar-title>Users</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    color="white"
                    hide-details
                    label="Search"
                    single-line
                ></v-text-field>
                <v-spacer></v-spacer>
                <v-btn text @click="initialize">
                    <v-icon>mdi-refresh</v-icon>
                </v-btn>
                <v-dialog v-model="dialog" max-width="500px">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            v-bind="attrs"
                            v-on="on"
                            text
                            @click="addUserDialog"
                        >
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                    </template>
                    <v-card>
                        <v-card-title>
                            <span class="text-h5">{{ formTitle }}</span>
                        </v-card-title>
                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field v-model="editedItem.username"
                                                      label="Username"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field
                                            v-model="editedItem.password"
                                            label="Password"
                                            type="password"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" md="4" sm="6">
                                        <v-select
                                            v-model="editedItem.role"
                                            :items="availableRoles"
                                            hide-details
                                            label="Role"
                                        ></v-select>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="close">
                                Cancel
                            </v-btn>
                            <v-btn color="blue darken-1" text @click="save">
                                Save
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="dialogDelete" max-width="500px">
                    <v-card>
                        <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                            <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-toolbar>
        </template>
        <template v-slot:item.visible="{ item }">
            <v-simple-checkbox
                v-model="item.visible"
                disabled
            ></v-simple-checkbox>
        </template>
        <template v-slot:item.actions="{ item }">
            <v-icon
                class="mr-2"
                small
                @click="editItem(item)"
            >
                mdi-pencil
            </v-icon>
            <v-icon
                small
                @click="deleteItem(item)"
            >
                mdi-delete
            </v-icon>
        </template>
        <template v-slot:no-data>
            <v-btn
                color="primary"
                @click="initialize"
            >
                Reset
            </v-btn>
        </template>
    </v-data-table>
</template>

<script>
export default {
    name: "UserManagement",
    data() {
        return {
            search: '',
            dialog: false,
            dialogDelete: false,
            editedIndex: -1,
            editedItem: {
                id: '',
                username: '',
                password: '',
                role: 'user'
            },
            defaultItem: {
                id: '',
                username: '',
                password: '',
                role: 'user'
            },

            availableRoles: ['user', 'admin'],
            password: '',
            username: '',
            isShowed: false,
            isSpecterTableLoading: false,

            userHeaders: [
                {
                    text: 'ID',
                    align: 'start',
                    sortable: true,
                    value: 'id',
                },
                {text: 'Username', value: 'username', sortable: true},
                {text: 'Role', value: 'role', sortable: true},
                {text: 'Actions', value: 'actions', sortable: false},
            ],
            usersList: []
        }
    },
    mounted() {
        this.initialize()
    },
    computed: {
        formTitle() {
            return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
        },
        getUsers() {
            return this.$store.getters.getUsersList
        },
    },
    methods: {
        initialize() {
            this.$store.dispatch('fetchUsersList')
                .then(() => this.usersList = this.getUsers)
        },
        editItem(item) {
            this.editedIndex = this.usersList.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialog = true
        },

        deleteItem(item) {
            this.editedIndex = this.usersList.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialogDelete = true
        },

        deleteItemConfirm() {
            this.$toast.info('Deleting user')
            console.log(this.editedItem)
            this.$store.dispatch('deleteUser', this.editedItem.id)
                .then(() => {
                    this.$toast.success('Successfully delete user')
                    this.initialize()
                })
            this.closeDelete()
        },

        close() {
            this.dialog = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },

        closeDelete() {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },

        save() {
            console.log(this.editedIndex)
            if (this.editedIndex === -1) {
                this.$toast.info('Adding new user')
                this.$store.dispatch('addNewUser', this.editedItem)
                    .then(() => {
                        this.$toast.success('Successfully add new user')
                        this.initialize()
                    })
            } else {
                this.$toast.info('Changing user profile')
                this.$store.dispatch('changeUser', this.editedItem)
                    .then(() => {
                        this.$toast.success('Successfully change user profile')
                        this.initialize()
                    })
            }
            this.close()
        },

        addUserDialog() {
            this.editedIndex = -1
            this.editedItem = Object.assign({}, this.defaultItem)
        }
    }
}
</script>

<style scoped>

</style>
