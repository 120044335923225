<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" max-width="50%" @click:outside="closeDialog">
            <v-card>
                <v-card-title>{{ title }}</v-card-title>
                <v-card-text>
                    <v-form class="login-form">
                        <v-text-field id="login-field"
                                      v-model="username"
                                      autofocus
                                      color="#4a66d2"
                                      dark
                                      filled
                                      label="Username: "
                                      required
                        ></v-text-field>
                        <v-text-field id="password-field"
                                      v-model="password"
                                      :append-icon="passwordVisible ? 'mdi-eye' : 'mdi-eye-off'"
                                      :type="passwordVisible ? 'text' : 'password'"
                                      color="#4a66d2"
                                      dark
                                      filled
                                      label="Password: "
                                      required
                                      @click:append="passwordVisible = !passwordVisible">
                        </v-text-field>
                    </v-form>
                </v-card-text>
                <v-card-actions class="justify-content-around">
                    <v-btn color="error" @click="closeDialog">Закрыть</v-btn>
                    <v-btn color="success" @click="login">Войти</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
export default {
    name: "AuthorizationDialog",
    props: {
        title: String,
        dialog: Boolean
    },
    data() {
        return {
            username: '',
            password: '',
            passwordVisible: false,
        }
    },
    methods: {
        login() {
            this.$emit('login', {username: this.username, password: this.password})
            this.$emit('close')
        },
        closeDialog() {
            this.$emit('close')
        }
    }
}
</script>

<style scoped>

</style>
