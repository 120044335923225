<template>
    <v-dialog v-model="showDialog" class="d-flex flex-grow" width="unset" @click:outside="closeDialog">
        <v-card class="d-flex align-center flex-column pb-4" color="primary" flat>
            <v-card class="d-flex align-center flex-column pb-4" color="primary" flat>
                <v-card-title>{{ $t('newLayerDialog/title') }}</v-card-title>
                <v-select id="new-layer-type-selector" v-model="layerType" :hide-details="true"
                          :items="Object.keys(layerTypes)" background-color="secondary"
                          class="w-75 align-center" color="white" item-color="secondary" solo></v-select>
            </v-card>

            <kmeans-layer v-if="checkLayerType('kmeans')" @closeDialog="closeDialog"></kmeans-layer>
            <ndi-layer v-if="checkLayerType('ndi')" :upBorder="upBorder" @closeDialog="closeDialog"></ndi-layer>
            <pca-layer v-if="checkLayerType('pca')" @closeDialog="closeDialog"></pca-layer>
            <plain-layer v-if="checkLayerType('plain')" @closeDialog="closeDialog"></plain-layer>
            <som-layer v-if="checkLayerType('som')" @closeDialog="closeDialog"></som-layer>

            <specific-layer v-if="checkLayerType('ndvi')" color="#00FF00" index-type="NDVI"
                            @closeDialog="closeDialog"></specific-layer>
            <specific-layer v-if="checkLayerType('ndwi')" color="#0000FF" index-type="NDWI"
                            @closeDialog="closeDialog"></specific-layer>
        </v-card>
    </v-dialog>
</template>

<script>
import KmeansLayer from "@/components/layers-view/KmeansLayer";
import NdiLayer from "@/components/layers-view/NdiLayer";
import PcaLayer from "@/components/layers-view/PcaLayer";
import PlainLayer from "@/components/layers-view/PlainLayer";
import SomLayer from "@/components/layers-view/SomLayer";
import SpecificLayer from "@/components/layers-view/SpecificLayer";

export default {
    name: 'NewLayerDialog',
    components: {SpecificLayer, SomLayer, PlainLayer, PcaLayer, NdiLayer, KmeansLayer},
    props: {
        showDialog: Boolean,
        upBorder: Number
    },
    data() {
        return {
            layerTypes: {
                'Hyperspecter Layer': 'plain',
                'NDI - Normalized Difference Index': 'ndi',
                'NDVI - Vegetation Index': 'ndvi',
                'NDWI - Water index': 'ndwi',
                'Principal Component Analysis': 'pca',
                'KMeans': 'kmeans',
                'Similarity objects map': 'som',
            },
            layerType: '',
        }
    },
    computed: {
        checkLayerType() {
            return type => {
                return this.layerTypes[this.layerType] === type
            }
        },
    },
    methods: {
        closeDialog() {
            this.$emit('closeDialog')
        },
    }
}
</script>

<style scoped>
.bg-color-primary {
    background-color: #4a66d2;
}
</style>
