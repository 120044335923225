import {camera_backend} from '../plugins/axios'
import backend from '../plugins/axios'

export function saveCameraConfig(config) {
    return backend.post(`/config`, config)
}

export function getCameraConfig() {
    return backend.get('/config')
}

export function getMemoryStatus() {
    return backend.get('/base/memory')
}

export function assembleSpecter(payload) {
    return backend.post('/video', payload)
}

export function uploadOnServer(payload) {
    return backend.post(`/server/${payload.endpoint}`, payload)
}

export function removeVideo(payload) {
    return backend.delete(`/flight/${payload.type}/${payload.id}`)
}

export function fetchVideoConfig() {
    return backend.get('/flights')
}

export function hypercamShowPicture(formData) {
    return camera_backend.post('/rec/hypercam_show_picture', formData)
}

export function rotateTripod(formData) {
    return camera_backend.post('/rec/tripod_rotate', formData)
}

export function startRecording(formData) {
    return camera_backend.post('/recording/start', formData)
}

