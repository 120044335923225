<template>
    <v-card class="d-flex align-center flex-column" color="primary" flat>
        <v-card-title>
            {{ $t('newLayerDialog/kmeansTitle') }}
        </v-card-title>
        <v-card-text class="w-75">
            <v-form ref="form" v-model="valid" class="d-flex flex-column justify-center">
                <v-text-field
                    v-model.number="clusters"
                    :label="$t('newLayerDialog/kmeansClusters')"
                    :rules="[rules.check_clusters_count]"
                    class="no-select"
                    clearable
                    color="white"
                    hint="Number from 1 to 255"
                    type="number">
                </v-text-field>
            </v-form>
        </v-card-text>
        <v-card-actions class="w-100 justify-space-around">
            <v-btn color="error" @click="closeDialog">{{ $t('button/close') }}</v-btn>
            <v-btn :disabled="!valid" color="green" @click="addNewLayer()">
                {{ $t('newLayerDialog/addBtn') }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import LayerFactory from "@/model/Layer";

export default {
    name: "KmeansLayer",
    data() {
        return {
            valid: true,
            rules: {
                check_clusters_count: value => value > 0 && value < 256
            },
            clusters: 4
        }
    },
    methods: {
        closeDialog() {
            this.$emit('closeDialog')
        },
        validate() {
            this.$refs.form.validate()
        },
        addNewLayer() {
            const clusters = this.clusters
            const layer_name = `Kmeans ${this.clusters}`
            const layer = new LayerFactory().createLayer('kmeans', {clusters, layer_name})
            this.$store.dispatch('computeLayer', {type: 'kmeans', layer: layer})

            this.closeDialog()
        }
    }
}
</script>

<style scoped>

</style>
